export const updateDisabledProperty = <T extends Object>(obj: T): T => {
  const updatedObj: T = { ...obj };

  Object.keys(updatedObj).forEach(key => {
    if (typeof updatedObj[key as keyof T] === "object" && updatedObj[key as keyof T] !== null) {
      // Если значение ключа — объект, ищем и заменяем "disabled" на false
      if ("disabled" in (updatedObj as any)[key]) {
        updatedObj[key as keyof T] = {
          ...updatedObj[key as keyof T],
          disabled: false,
        };
      }
    }
  });

  return updatedObj;
};
