import styles from "./styles.module.scss";
import { ConfigProvider, DatePicker, Input, Select } from "antd";
import { useEffect, useState } from "react";
import type { Contragent } from "../../../../types/loan";
import classNames from "classnames";
import dayjs from "dayjs";
import ru_RU from "antd/lib/locale/ru_RU";

const MIN_DATE = "2024-12-01";

export interface Props {
  onUpdate: (contragent: any, responsible: any, documentNumber: string, date: string) => void;
  contragents: any[];
  responsible: any[];
}

export const CreateLoanHeader = ({ onUpdate, contragents, responsible }: Props) => {
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [currentContragent, setCurrentContragent] = useState<null | Contragent>(null);
  const [currentResponsible, setCurrentResponsible] = useState(null);
  const [date, setDate] = useState<string | null>(null);

  const onContragentSelect = (event: any) => {
    setCurrentContragent(event);
  };

  const onResponsibleSelect = (event: any) => {
    setCurrentResponsible(event);
  };

  useEffect(() => {
    if (date === null) return;

    onUpdate(currentContragent, currentResponsible, documentNumber, date);
  }, [currentContragent, currentResponsible, date, documentNumber, onUpdate]);

  return (
    <div className={styles.header}>
      <div className={styles.headerItem}>
        <label className={styles.inputLabel}>Номер документа</label>
        <Input
          onInput={(e: any) => setDocumentNumber(e.target.value)}
          className={styles.inputItem}
          placeholder="Номер документа"
        />
      </div>

      <div className={styles.headerItem}>
        <label className={styles.inputLabel}>Ответственный</label>
        <Select
          className={classNames(styles.inputItem, styles.select)}
          placeholder="Выбор ответственного"
          onChange={onResponsibleSelect}
        >
          {responsible?.map(item => (
            <Select.Option key={item.id} value={item.id} label={item.name}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className={styles.headerItem}>
        <label className={styles.inputLabel}>Контрагент</label>
        <Select
          className={classNames(styles.inputItem, styles.select)}
          placeholder="Выбор контрагента"
          onChange={onContragentSelect}
          showSearch
          optionFilterProp="children"
        >
          {contragents.map(contragent => (
            <Select.Option key={contragent.id} value={contragent.id} label={contragent.title}>
              {contragent.title}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.headerItem}>
        <label className={styles.inputLabel}>Дата проведения</label>
        <ConfigProvider locale={ru_RU}>
          <DatePicker
            onChange={(e: any) => setDate(e ? e.format("YYYY-MM-DD") : null)}
            className={styles.inputItem}
            type="date"
            minDate={dayjs(MIN_DATE)}
            format={"DD.MM.YYYY"}
            placeholder="Дата проведения"
          />
        </ConfigProvider>
      </div>
    </div>
  );
};
