import {api} from "./api";
import type {OddsTableItem} from "../types/odds";

export const BASE_API_URL_V3 = `${process.env.REACT_APP_API_BASE}/api/v3/`;
export const BASE_API_CONFIG = { baseURL: BASE_API_URL_V3 };

export async function fetchOdds(body: { date: string }, controller: AbortController | null): Promise<OddsTableItem[]> {
    const response = await api.post('/reports/cfr/get', body, {
        signal: controller?.signal,
        ...BASE_API_CONFIG
    });
    return response.data;
}

export async function saveOdds(body: any[]): Promise<OddsTableItem[]> {
    const response = await api.post('/reports/cfr/update', body, BASE_API_CONFIG);
    return response.data;
}

export async function updateOdds(body: { date: string }): Promise<{ message: string }>  {
    const response = await api.post('/reports/cfr/refresh', body, BASE_API_CONFIG);
    return response.data;
}