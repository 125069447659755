import React, {useCallback, useState} from "react";
import {Table} from "antd";
import {
  IMatrixRow,
  IMatrixSubRow,
  IPermissions,
  PermissionTypeKey,
  PermissionTypeValue,
  SendPermissions
} from "pages/access-rights/types";
import cls from "./styles.module.scss";
import {Nullable} from "assets/types";
import {PopupModal} from "widgets";
import {useStore} from "../../../../store";
import {userGetRolesOnSpot, userSetPermissionsOnSpot} from "../../../../api/roles";
import {RulesMatrix} from "../rules-matrix";
import {showAlertPopup} from "../../../../ui/alert";
import {EmployeeTypeModal} from './modals/employee-type-modal';
import {IUser} from 'pages/salary-fund/types';
import {EmployeeInfoModal} from './modals/emoloyment-info-modal';
import {ShowAlertPopupType} from "../../../../utils/const/shared";

const { Column } = Table;

interface EmployeesTableProps {
  users: IUser[];
  isLoading: boolean;
}

const PermissionLabels: Record<PermissionTypeKey,PermissionTypeValue> = {
  Invoice: "Накладная",
  Cash_Order: "Кассовый ордер",
  Manual_Input: "Ручной ввод",
  Advance_Report: "Авансовый отчет",
  Inventory: "Инвентаризация",
  Scheduling: "Планирование графика",
  Bank_Statements: "Банковские выписки",
  Act_Service: "Акты по услугам",
  Advance_Report_Service: "Авансовые отчёты по услугам",
  Stock_Balance: "Отчёт об остатках",
};

enum EmployeeType {
  Трудоустроенный = 1,
  Самозанятый
}

export const EmployeesTable: React.FC<EmployeesTableProps> = (props) => {

  const {
    users,
    isLoading,
  } = props;

  const { RootStore } = useStore();

  const [currentUser,setCurrentUser] = useState<Nullable<IUser>>(null);
  const [isShownModal,setIsShownModal] = useState<boolean>(false);
  const [isEditEmployeeTypeModalOpen,setIsEditEmployeeTypeModalOpen] = useState(false);
  const [isEditEmployeeInfoModalOpen,setIsEditEmployeeInfoModalOpen] = useState(false);

  const [permissionCollection,setPermissionCollection] = useState<Nullable<IPermissions>>(null);
  const [sendData,setSendData] = useState<IMatrixRow[]>([]);

  const onEmployeeTypeClickHandler = useCallback((user: IUser) => {
    setCurrentUser(user);
    setIsEditEmployeeTypeModalOpen(true);
  },[]);

  const onEmployeeTypeModalClose = useCallback(() => {
    setCurrentUser(null);
    setIsEditEmployeeTypeModalOpen(false);
  },[]);
  const onEmployeeInfoModalClose = useCallback(() => {
    setCurrentUser(null);
    setIsEditEmployeeInfoModalOpen(false);
  },[]);

  const onEditPermissionsClickHandler = async (user: IUser) => {

    const response = await userGetRolesOnSpot(user.spot_id,user.id);

    if (response?.data) {
      console.log(response?.data);
      setPermissionCollection(response?.data);
    }

    setCurrentUser(user);

    setIsShownModal(true);
    RootStore.setModalIsActive(true);
  };

  const onEditPermissionsSaveAction = async () => {

    if (!currentUser?.id) {
      showAlertPopup("Ошибка","Текущий пользователь не определен", ShowAlertPopupType.ERROR);
      return;
    }

    const result: SendPermissions = { permissions: {},user_id: null };

    sendData.forEach((item: IMatrixRow,index: number) => {
      item.rows.forEach((item: IMatrixSubRow,index: number) => {
        result.permissions[item.slug] = Number(item.value);
      });
    });

    result.user_id = currentUser?.id;
    await userSetPermissionsOnSpot(result);

    setIsShownModal(false);
    RootStore.setModalIsActive(false);
  };

  const handleInputChange = (e: IMatrixRow[]) => setSendData(e);

  const locale = { emptyText: "Данные отсутствуют" };

  return (
    <div>
      <Table loading={isLoading} dataSource={users} locale={locale}>
        <Column
          title="Фамилия"
          key="surname"
          align="center"
          dataIndex={'surname'}
        />
        <Column
          title="Имя"
          key="name"
          align="center"
          dataIndex={'name'}
        />
        <Column
          title="Должность"
          key="job_title"
          dataIndex="job_title"
          align="center"
        />
        <Column
          title="Вид трудоустройства"
          key="employee_type"
          align="center"
          render={(_,user: IUser) =>
            <button
              onClick={() => { onEmployeeTypeClickHandler(user); }}
              className={cls.itemButton}
            >
              {user.employee_type === null ? 'Не задан' : EmployeeType[user.employee_type]}
            </button>
          }
        />
        <Column
          title="Права доступа"
          key="options"
          align="center"
          render={(_: any,user: IUser) => (
            (user?.allow_edit_permissions &&
              <button
                onClick={async () => await onEditPermissionsClickHandler(user)}
                className={cls.itemButton}
              >
                Настроить права
              </button>)
            || <span>Нет опций</span>
          )}
        />
        <Column
          title="Карточка работника"
          key="options"
          align="center"
          render={(_: any,user: IUser) => (
            <button
              onClick={() => { setCurrentUser(user); setIsEditEmployeeInfoModalOpen(true); }}
              className={cls.itemButton}
            >
              Редактировать данные
            </button>
          )}
        />
      </Table>
      <div>
        {isShownModal &&
          <PopupModal
            title="Настройка прав пользователя"
            onSave={async () => { await onEditPermissionsSaveAction(); }}
            onClose={() => setIsShownModal(false)}
            isButtonDisabled={!currentUser?.id}
            maxWidth={"750px"}
          >
            {permissionCollection &&
              <div className="modalMainContent">
                <RulesMatrix
                  PermissionLabels={PermissionLabels}
                  onChange={handleInputChange}
                  data={permissionCollection}
                />
              </div>
            }
          </PopupModal>
        }
        {isEditEmployeeTypeModalOpen && <EmployeeTypeModal user={currentUser} onClose={onEmployeeTypeModalClose} />}
        {isEditEmployeeInfoModalOpen && <EmployeeInfoModal user={currentUser} onClose={onEmployeeInfoModalClose} />}
      </div>
    </div>
  );
};
