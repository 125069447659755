import {type ChangeEvent, type CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";
import cls from './styles.module.scss';
import {useClickAway} from "react-use";
import {dayjs} from "utils/plugins/dayjs";
import type {OddsTableItem} from "../../../../types/odds";
import type {OddsDay} from "../../types";

export interface Props {
    styles: CSSProperties;
    data: OddsTableItem;
    day: OddsDay;
    onSubmit: (data: OddsTableItem, day: OddsDay, amount: number | string) => void;
    code: string;
}

const schemaDecimal = /^(?:\d+(\.\d+)?|\d*\.)$/;

export const TableCell = ({ styles, data, day, onSubmit, code }: Props) => {
    const cellRef = useRef(null);
    const inputRef = useRef<null | HTMLInputElement>(null);
    const [isEdited, setIsEdited] = useState(false);
    const [isEditable, setEditable] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const getDayAmount = (data: OddsTableItem, day: OddsDay) => {
        if (hideLabel) return "";
        const item = data.results.find((el) => el.date === dayjs(day.date, 'DD.MM.YY').format('YYYY-MM-DD'));

        if (!item) return renderCellNumberValue(0);
        else return renderCellNumberValue(item.amount);
    };

    const renderCellNumberValue = useCallback((value: number | string) => {
        if (!value) return 0;

        return new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(Number(value));
    }, []);

    const handleCellClick = () => {
        if (!data.is_editable) return;
        const item = data.results.find((el) => el.date === dayjs(day.date).format('YYYY-DD-MM'));
        setEditable(true);
        const value = !item?.amount ? "" : item?.amount;
        setInputValue(`${value}`);
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const target = e.target;
        let value = target.value;

        if (!schemaDecimal.test(value)) {
            value = value.slice(0, -1);
            e.target.value = value;
        }

        setInputValue(value)
    }

    const submit = async () => {
        if (!isEdited) {
            setEditable(false);
            setIsEdited(false);
            return;
        }

        if (isEditable) {
            onSubmit(data, day, inputValue);
            setEditable(false);
        }
    };

    const handleKeyDown = async (event: any) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            await submit();
            return;
        }
    }

    const hideLabel = useMemo(() => {
        return code === 'sverka_nalichnyh_i_beznalichnyh';
    }, [code]);

    useClickAway(cellRef, submit);

    useEffect(() => {
        if (isEditable && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditable]);

    return <td style={styles} onClick={handleCellClick} ref={cellRef}>
        {!isEditable && <span>
            {getDayAmount(data, day)}
        </span>}
        {isEditable && <span>
            {<input
                type="text"
                onChange={handleInput}
                value={inputValue}
                className={cls.editInput}
                onKeyDown={handleKeyDown}
                ref={inputRef}
            />}
        </span>}
    </td>
}