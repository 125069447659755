export interface IStatus {
  name: string;
  name_ru: string;
  value: number;
  is_active: boolean;
}

export interface IJobTitle {
  id: number;
  title_en: string;
  title_ru: string;
}

export interface IUser {
  allow_edit_permissions: boolean;
  id: number;
  employee_type: number;
  employee_type_at: string | null;
  name: string;
  surname: string;
  third_name: string | null;
  email: string;
  phone: string | null;
  timezone: number;
  dkk: number;
  telegram: string | null;
  date_of_born: string | null;
  avatar: string[] | null;
  award_types: IUserAwardType[] | null;
  spot: string | null;
  spot_id: number;
  spot_timezone: number;
  job_title: IJobTitle["title_ru"];
  job_title_id: IJobTitle["id"];
  job_title_en: string;
  job_title_up_to: null;
  job_title_down_to: null;
  snils: string;
  inn: string;
  fix_pay: number | null;
}

export interface IUserAwardType {
  award_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  user_employee_type_id: number;
  user_id: IUser["id"];
}

export interface IAward {
  id: string;
  user_id: IUser["id"];
  spot_id: number;
  section_status_id: number;
  plan_date: string | null;
  plan_gross: number | null;
  plan_net: number | null;
  fact_date: string | null;
  fact_gross: number | null;
  fact_net: number | null;
  approved_date: string | null;
  approved_gross: number | null;
  approved_net: number | null;
  paid_date: string | null;
  paid_gross: number | null;
  paid_net: number | null;
  taxes: number | null;

  //2024-08-26T22:54:45.000000Z - пример
  created_at: string;
  updated_at: string;
}

interface IDoubleAward {
  user_id: IUser["id"];
  spot_id: number;
  section_status_id: number;
  first_period_plan_date: string | null;
  first_period_plan_gross: number | null;
  first_period_plan_net: number | null;
  first_period_fact_date: string | null;
  first_period_fact_gross: number | null;
  first_period_fact_net: number | null;
  first_period_approved_date: string | null;
  first_period_approved_gross: number | null;
  first_period_approved_net: number | null;
  first_period_paid_date: string | null;
  first_period_paid_gross: number | null;
  first_period_paid_net: number | null;
  second_period_plan_date: string | null;
  second_period_plan_gross: number | null;
  second_period_plan_net: number | null;
  second_period_fact_date: string | null;
  second_period_fact_gross: number | null;
  second_period_fact_net: number | null;
  second_period_approved_date: string | null;
  second_period_approved_gross: number | null;
  second_period_approved_net: number | null;
  second_period_paid_date: string | null;
  second_period_paid_gross: number | null;
  second_period_paid_net: number | null;
  total_planned_gross: number | null;
  total_planned_net: number | null;
  total_fact_gross: number | null;
  total_fact_net: number | null;
  total_approved_gross: number | null;
  total_approved_net: number | null;
  total_paid_gross: number | null;
  total_paid_net: number | null;
}

export interface IShiftAward extends IDoubleAward {}

export interface IBonusAward extends IAward {}

export interface IContractStatus {
  name: string;
  name_ru: string;
  value: number;
}

export interface IContract {
  id: number;
  spot_id: number;
  user_id: IUser["id"];
  date: string;
  title: string;
  status: IContractStatus["value"];
  plan_award: number;
  fact_award: number;
  employee_comment: string | null;
  manager_comment: string | null;
  description: string | null;
  created_at: string;
  updated_at: string;
}

export interface IContractAward extends IAward {
  contract_id: IContract["id"];
  status: IStatus["value"];
}

export interface IBonusTableItem extends IBonusAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IContractTableItem extends IContractAward {
  contract: IContract["title"];
  contract_status: IContract["status"];
  user: string;
}

export interface IShiftTableItem extends IShiftAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IGeneralAward {
  first_period_approved_gross: number;
  first_period_approved_net: number;
  first_period_paid_gross: number;
  first_period_paid_net: number;
  first_period_plan_gross: number;
  first_period_plan_net: number;
  monthly_approved_gross: number;
  monthly_approved_net: number;
  monthly_paid_gross: number;
  monthly_paid_net: number;
  monthly_plan_gross: number;
  monthly_plan_net: number;
  second_period_approved_gross: number;
  second_period_approved_net: number;
  second_period_paid_gross: number;
  second_period_paid_net: number;
  second_period_plan_gross: number;
  second_period_plan_net: number;
  user_id: IUser["id"];
}

export interface IGeneralTableItem extends IGeneralAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IManagerAward extends IDoubleAward {
  id: string;
}
export interface IManagerTableItem extends IManagerAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface ITableItems<T> {
  barista: T[];
  helpers: T[];
  joe: T[];
}

//#region Удержания и выплаты
export interface IHoldAndPaid {
  id: number;
  spot_id: number;
  //"YYYY-MM-DD"
  period: string;
  user_id: IUser["id"];
  user: string;
  user_job_id: IJobTitle["id"];
  user_job_title: IJobTitle["title_ru"];
  needle_last_period: number;
  approved_net: number;
  approved_gross: number;
  hold_by_ndfl: number;
  hold_by_inventory: number;
  hold_by_writeoffs: number;
  needle_with_holds: number;
  paid: number;
  needle: number;
  is_helper: boolean;
}

export interface IHoldAndPaidTotal {
  needle_last_period: number;
  approved_net: number;
  approved_gross: number;
  hold_by_ndfl: number;
  hold_by_inventory: number;
  hold_by_writeoffs: number;
  needle_with_holds: number;
  paid: number;
  needle: number;
}

export interface IWriteoff {
  guid_1c: string;
  date: string;
  number: string;
  user_id: number | null;
  sum: number;
  positions: IWriteoffPosition[];
}

export interface IWriteoffPosition {
  id: string;
  name: string;
  quantity: number;
  sum: number;
}
//#endregion
//#region ТБУ
export interface IBepInfo {
  section: IBepSection;
  statuses: IStatus[];
  bep: number;
  hours: IBepHours[];
  plan_revenue: IBepRevenue[];
  fact_revenue: IBepRevenue[];
  results: {
    plan_base: number;
    fact_base: number;
  };
  users: IBepUser[];
}
export interface IBepSection {
  id: number;
  spot_id: number;
  //YYYY-MM-DD HH:mm:ssZ
  date: string;
  section: number;
  status: number;
  first_period_status: number | null;
  second_period_status: number | null;
  //YYYY-MM-DD HH:mm:ssZ
  created_at: string;
  //YYYY-MM-DD HH:mm:ssZ
  updated_at: string;
}
export interface IBepHours {
  //YYYY-MM-DD
  date: string;
  manager_hours_plan: 0;
  manager_hours_fact: 0;
  sum_hours_plan: 0;
  sum_hours_fact: 0;
}
export interface IBepRevenue {
  //YYYY-MM-DD
  date: string;
  amount: number;
  base: number;
}
export interface IBepUser {
  id: IUser["id"];
  name: string;
  job_title_id: IJobTitle["id"];
  job_title: IJobTitle["title_ru"];
  is_helper: boolean;
  items: IBepUserItem[];
  results: IBepUserResults;
  approved: IBepUserApproved;
}

export interface IBepUserItem {
  id: number;
  spot_id: number;
  user_id: number;
  section_status_id: number;
  //"YYYY-MM-DD"
  date: string;
  hours_plan: number;
  hours_fact: number;
  amount_plan_gross: number;
  amount_plan_net: number;
  amount_fact_gross: number;
  amount_fact_net: number;
}
export interface IBepUserResults {
  amount_fact_gross: number;
  amount_fact_net: number;
  amount_plan_gross: number;
  amount_plan_net: number;
}
export interface IBepUserApproved {
  approved_gross: number;
  approved_net: number;
}
//#endregion

export enum FundTabs {
  "Выплаты и удержания" = 0,
  "Вознаграждения за смены",
  "Контракты",
  "Премии ТБУ",
  "Премии",
  "Управляющий",
}

export enum FieldTypes {
  "plan" = "plan",
  "fact" = "fact",
  "approved" = "approved",
  "paid" = "paid",
}
