import React, {type ChangeEvent, useEffect, useState} from "react";
import styles from "./styles.module.scss";
import dayjs from "dayjs";
import {Button} from "ui-new/button";
import {DataTable, type DataTableHeader} from "../../ui-new/table";
import {getNomenclatures} from "../../api/registryDocument";
import {Input, Select} from "antd";
import {nanoid} from "nanoid";
import type {Nullable, Undefinable} from "../../assets/types";
import {observer} from "mobx-react-lite";
import {createLoan, getContragents} from "api/loan";
import {toJS} from "mobx";
import {type Responsible} from "./modals/fill-loan-modal/FillLoanModal";
import {showAlertPopup} from "ui/alert";
import {useNavigate} from "react-router";
import {headers} from "utils/const/loan/create-loan";
import {useStore} from "../../store";
import {CreateLoanHeader} from "./forms/header/header";
import {getResponsible} from "../../api";
import type {Contragent} from "../../types/loan";
import {ConfirmModal} from "../../widgets/modal/confirm-modal";
import {ShowAlertPopupType} from "../../utils/const/shared";

export interface AdditionalData {
  id: string;
  title: string;
  value: Undefinable<string | number | null>;
  label: string;
}

export interface TableItem {
  id: string;
  nomenclature_id: Nullable<string>;
  value: Nullable<string>;
  cost: Nullable<string>;
  unit?: Nullable<string>;
}

const initialTableItem = {
  id: nanoid(),
  nomenclature_id: null,
  value: null,
  cost: null,
  unit: null,
};

const schema = /^(?:\d+(\.\d{1,2})?|\d*\.)$/;

export const CreateLoan = observer(() => {
  const navigate = useNavigate();

  const { RootStore } = useStore();
  const {
    RoleStore: { spotId },
  } = RootStore;

  const [tableItems, setTableItems] = useState<TableItem[]>([{ ...initialTableItem }]);
  const [nomenclatures, setNomenclatures] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contragents, setContragents] = useState<Contragent[]>([]);
  const [currentContragent, setCurrentContragent] = useState<null | Contragent>(null);
  const [responsible, setResponsible] = useState<Responsible[]>([]);
  const [currentResponsible, setCurrentResponsible] = useState(null);
  const [currentDocumentNumber, setCurrentDocumentNumber] = useState<string>("");
  const [currentDate, setCurrentDate] = useState<string | null>();
  const [isConfirmModal, setConfirmModal] = useState(false);

  const addNewPosition = () => {
    setTableItems((prev: any) => [...prev, { ...initialTableItem, id: nanoid() }]);
  };

  const onCreateClick = async () => {
    try {
      await createLoan({
        number: String(currentDocumentNumber),
        from_id: Number(toJS(spotId)),
        to_id: Number(currentContragent),
        date: dayjs(currentDate).format("YYYY-MM-DD"),
        user_id: Number(currentResponsible),
        positions: tableItems,
      });

      navigate("/main/loan");
      showAlertPopup("Успешно!", "Создание прошло успешно!", ShowAlertPopupType.SUCCESS);
    } catch (e) {
    } finally {
      setConfirmModal(false);
    }
  };

  const onSelect = (event: string, itemId: string) => {
    const nomenclature = nomenclatures.find((nomenclature: any) => nomenclature.id === event);

    setTableItems((prev: any) => {
      return prev.map((item: any) => {
        if (item.id === itemId) {
          return {
            ...item,
            nomenclature_id: event,
            unit: nomenclature.unit,
          };
        }

        return { ...item };
      });
    });
  };

  const onInputHandler = (event: any, itemId: string, field: keyof TableItem) => {
    const target = event.target as HTMLInputElement;

    let value = target.value;

    if (!schema.test(value)) {
      value = value.slice(0, -1);
      event.target.value = value;
    }

    setTableItems(prev => {
      return prev.map((item: TableItem) => {
        if (item.id === itemId) {
          return { ...item, [field]: target.value };
        }

        return { ...item };
      });
    });
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    const target = e.target;
    let value = target.value;

    if (!schema.test(value)) {
      value = value.slice(0, -1);
      e.target.value = value;
    }

    if (Number(value) > 100) {
      value = String(100);
      e.target.value = value;
    }

    onChange(value);
  };

  const selectFilterOptions = (input: string, option: any) => {
    return (typeof option?.label === "string" ? option.label.toLowerCase() : "").includes(
      input.toLowerCase()
    );
  };

  const handleTableDelete = (itemId: number | string) => {
    if (tableItems.length === 1) return;

    setTableItems(prev => {
      return prev.filter(item => {
        return item.id !== itemId;
      });
    });
  };

  const handleHeaderUpdate = (
    contragent: any,
    responsible: any,
    documentNumber: string,
    date: string
  ) => {
    setCurrentContragent(contragent);
    setCurrentResponsible(responsible);
    setCurrentDocumentNumber(documentNumber);
    setCurrentDate(date);
  };

  const handleConfirmModal = () => {
    const hasEmptyPosition = Object.values(tableItems).some(item =>
      Object.values(item).some(value => !value)
    );

    if (!currentContragent || !currentResponsible || !currentDocumentNumber || !currentDate) {
      showAlertPopup("Ошбика", "Не все данные заполнены!", ShowAlertPopupType.ERROR);
      return;
    } else if (hasEmptyPosition) {
      showAlertPopup("Ошбика", "Заполни все позиции", ShowAlertPopupType.ERROR);
      return;
    }

    setConfirmModal(true);
  };

  const tableCell = (item: any, header: DataTableHeader<any>) => {
    if (!nomenclatures.length) return;

    switch (header.value) {
      case "nomenclature_id":
        return (
          <Select
            showSearch
            value={item?.nomenclature_id}
            onChange={event => onSelect(event, item?.id)}
            placeholder="Выбор номенклатуры"
            className={styles.nomenclatureSelect}
            filterOption={selectFilterOptions}
            optionFilterProp="children"
          >
            {nomenclatures.map((nomenclature: any) => (
              <Select.Option
                key={nomenclature?.id}
                value={nomenclature?.id}
                label={nomenclature?.name}
              >
                {nomenclature?.name}
              </Select.Option>
            ))}
          </Select>
        );
      case "value":
        return (
          <Input
            placeholder="Количество"
            onInput={event => onInputHandler(event, item?.id, "value")}
            value={item?.value}
          />
        );
      case "cost":
        return (
          <Input
            placeholder="Себестоимость"
            onInput={event => onInputHandler(event, item?.id, "cost")}
            value={item?.cost}
          />
        );
      case "unit":
        return <span className={styles.unitText}>{item?.unit ? item.unit : ""}</span>;
    }
  };

  useEffect(() => {
    setIsLoading(true);

    getNomenclatures(["GOODS", "SERVICE"])
      .then(result => setNomenclatures(result))
      .catch(err => {
        return;
      })
      .finally(() => setIsLoading(false));

    getContragents()
      .then(res => {
        setContragents([...res]);
        setCurrentContragent(res[0]);
      })
      .catch(() => {
        return;
      });

    getResponsible()
      .then(res => {
        setResponsible(res);
      })
      .catch(() => {
        return;
      });
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <h1 className={styles.pageHeaderText}>Создание реализации</h1>

        <div className={styles.buttonContainer}>
          <Button onClick={handleConfirmModal}>Сохранить</Button>
        </div>
      </div>

      <CreateLoanHeader
        responsible={responsible}
        contragents={contragents}
        onUpdate={handleHeaderUpdate}
      />

      <DataTable
        isLoading={isLoading}
        headers={headers}
        items={tableItems}
        itemKey={"id"}
        cellPadding="0"
        children={(item, header) => tableCell(item, header)}
        onDelete={handleTableDelete}
      />

      <Button onClick={addNewPosition} className={styles.addPositionButton}>
        Добавить позицию
      </Button>

      {isConfirmModal && (
        <ConfirmModal
          onClose={() => setConfirmModal(false)}
          onConfirm={onCreateClick}
          title="Оформить реализацию?"
          messages={["Вы точно уверены, что готовы оформить реализацию?"]}
          confirmButtonLabel="Да"
        />
      )}
    </div>
  );
});
