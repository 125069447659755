import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useStore } from "store";
import { EInputStyleVariant, Input } from "ui-new/input";
import { Modal } from "widgets/modal";
import { ModalButtons } from "widgets/modal/buttons";
import cls from "./reward-modal-styles.module.scss";
import * as yup from "yup";
import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  amount: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .min(0, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
    .when("shortage", (deps, schema) => {
      const shortage = deps[0];
      if (!shortage) return schema;
      return schema.max(shortage, "Сумма не может быть больше суммы недостачи");
    })
    .required(ValidationMessages.get(ValidationTypes.required)),
});

interface FormValues {
  shortage?: number;
  amount: number;
}

interface RewardByShortagesModalProps {}

const RewardByShortagesModalComponent: FC<RewardByShortagesModalProps> = () => {
  const {
    RootStore: {
      SalaryFundStore: { fetchRewardByShortages, shortagesAmount, setRewardByShortagesModalIsOpen },
    },
  } = useStore();

  const { handleSubmit, control, setValue } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: null as unknown as number,
      shortage: shortagesAmount ?? 0,
    },
  });

  const onClose = () => setRewardByShortagesModalIsOpen(false);
  const onSave = ({ amount }: FormValues) => {
    fetchRewardByShortages(amount).then(onClose);
  };
  return (
    <Modal title="Укажите сумму удержания для расчета" onClose={onClose} maxWidth="440px">
      <div className={cls["form"]}>
        <p className={cls["sub-header"]}>
          Сумма будет распределена по работникам пропорционально количеству отработанных часов.
        </p>
        <div className={cls["fields-wrapper"]}>
          <div className={cls["field-container"]}>
            <label className={`${cls["label"]} ${cls["disabled"]}`}>Сумма недостачи</label>
            <span className={cls["sub-label"]}>
              Это общая сумма недостачи в результате инвентаризации
            </span>
            <Input
              variant={EInputStyleVariant.basicInput}
              control={control}
              name="shortage"
              type="number"
              placeholder="Сумма недостачи"
              inputProps={{ disabled: true }}
            />
          </div>
          <div className={cls["field-container"]}>
            <label className={cls["label"]}>Сумма удержания</label>
            <span className={cls["sub-label"]}>
              Эта сумма будет распределена между работниками.
            </span>
            <Input
              variant={EInputStyleVariant.basicInput}
              control={control}
              name="amount"
              type="number"
              placeholder="Укажи сумму удержания"
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ModalButtons
            primaryButtonLabel="Рассчитать"
            secondaryButtonLabel="Отменить"
            onPrimaryButtonClick={handleSubmit(onSave)}
            onSecondaryButtonClick={onClose}
          />
        </div>
      </div>
    </Modal>
  );
};

export const RewardByShortagesModal = observer(RewardByShortagesModalComponent);
