import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "../../../store/rootStore";
import {
  approveMoneyTransaction,
  createMoneyTransaction,
  deleteMoneyTransaction,
  getBusinessHolders,
  getCommitments,
  getDirectoryContragentsPaginated,
  getEmployeesAndHelpers,
  getMoneyTransaction,
  getNeedleToPay,
  getPnLArticles,
  getTaxes,
  getTransactionCashFlowArticles,
  getTransactionOperationTypes,
  getTransactionPaymentTypes,
  getTransactionStatuses,
  getTransactionTypes,
  IContragentMetaProps,
  unapproveMoneyTransaction,
  updateMoneyTransaction,
} from "api";
import { showConfirmModal } from "ui-new/alert/confirm";
import { getEmployees } from "api/accessRights";
import { IUser } from "pages/salary-fund/types";
import {
  IMoneyTransaction,
  IMoneyTransactionCashFlowArticle,
  IMoneyTransactionCommitment,
  IMoneyTransactionFieldsByOperation,
  IMoneyTransactionOperationType,
  IMoneyTransactionPayType,
  IMoneyTransactionPnLArticle,
  IMoneyTransactionStatus,
  IMoneyTransactionTax,
  IMoneyTransactionType,
} from "types/money-transactions";
import { IBusinessHolder, IContragent } from "types/directories";
import { showNotificationModal } from "ui-new/alert";
import { showAlertPopup } from "ui/alert";
import { ShowAlertPopupType } from "../../../utils/const/shared";

export const TAX_TYPE_ID_CLEAR = 5;

export class EditMoneyTransactionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  transaction: IMoneyTransaction | null = null;
  transactionIsLoading = false;
  transactionIsCreating = false;

  setTransactionIsLoading = (value: boolean) => {
    this.transactionIsLoading = value;
  };

  setTransactionIsCreating = (value: boolean) => {
    this.transactionIsCreating = value;
  };

  setTransaction = (t: IMoneyTransaction | null) => {
    this.transaction = t;
  };

  statuses: IMoneyTransactionStatus[] = [];
  types: IMoneyTransactionType[] = [];
  payTypes: IMoneyTransactionPayType[] = [];
  operationTypes: IMoneyTransactionOperationType[] = [];
  cashFlowArticles: IMoneyTransactionCashFlowArticle[] = [];
  pnlArticles: IMoneyTransactionPnLArticle[] = [];
  commitments: IMoneyTransactionCommitment[] = [];
  taxes: IMoneyTransactionTax[] = [];
  employees: IUser[] = [];
  businessHolders: IBusinessHolder[] = [];
  contragents: IContragent[] = [];
  contragentsIsLoading = false;
  contragentsMeta: IContragentMetaProps | null = null;
  formFields: IMoneyTransactionFieldsByOperation | null = null;
  employeesAndHelpers: IUser[] = [];

  setFormFields = (fields: typeof this.formFields) => {
    this.formFields = fields;
  };

  fetchGetTransaction = async (transactionId: IMoneyTransaction["id"]) => {
    this.setTransactionIsLoading(true);
    const response = await getMoneyTransaction(transactionId);
    this.setTransactionIsLoading(false);
    if (!response) return;

    this.setTransaction(response);
  };

  fetchCreateTransaction = async (body: Partial<IMoneyTransaction>) => {
    this.setTransactionIsCreating(true);
    const response = await createMoneyTransaction(body);
    if (!response) {
      this.setTransactionIsCreating(false);
      throw new Error("Что-то пошло не так");
    }
    this.transaction = response;
    this.setTransactionIsCreating(false);
    showAlertPopup("Успешно!", "Денежная операция создана", ShowAlertPopupType.SUCCESS);
    return this.transaction;
  };

  updateTransactionConfirm = (body: Partial<IMoneyTransaction>, onConfirm?: () => void) => {
    showConfirmModal({
      title: "Обновить денежную операцию?",
      confirmButtonLabel: "Обновить",
      onConfirm: () => this.fetchUpdateTransaction(body).then(onConfirm),
      minWidth: "350px",
    });
  };

  fetchUpdateTransaction = async (body: Partial<IMoneyTransaction>) => {
    this.setTransactionIsLoading(true);
    const response = await updateMoneyTransaction(body);
    if (!response) {
      this.setTransactionIsLoading(false);
      return;
    }
    this.transaction = response;
    this.setTransactionIsLoading(false);
    showAlertPopup("Успешно!", "Денежная операция обновлена", ShowAlertPopupType.SUCCESS);
  };

  approveTransactionConfirm = (body: Partial<IMoneyTransaction>, onConfirm?: () => void) => {
    if (body.id === undefined) {
      showNotificationModal({
        title: "Ошибка",
        errors: ["Не выбрана денежная операция, попробуйте обновить страницу"],
        type: "failure",
      });
      return;
    }

    showConfirmModal({
      title: "Утвердить денежную операцию?",
      confirmButtonLabel: "Утвердить",
      onConfirm: () =>
        this.fetchUpdateTransaction(body).then(() =>
          this.fetchApproveTransaction(body.id!).then(onConfirm)
        ),
    });
  };

  fetchApproveTransaction = async (transactionId: IMoneyTransaction["id"]) => {
    this.setTransactionIsLoading(true);
    const response = await approveMoneyTransaction(transactionId);
    if (!response) {
      this.setTransactionIsLoading(false);
      return;
    }
    this.transaction = response;
    this.setTransactionIsLoading(false);
  };

  unapproveTransactionConfirm = (onConfirm?: () => void) => {
    showConfirmModal({
      title: "Распровести денежную операцию?",
      confirmButtonLabel: "Распровести",
      onConfirm: () => this.fetchUnapproveTransaction(this.transaction!.id!).then(onConfirm),
    });
    return;
  };

  private fetchUnapproveTransaction = async (transactionId: IMoneyTransaction["id"]) => {
    this.setTransactionIsLoading(true);
    const response = await unapproveMoneyTransaction(transactionId);
    if (!response) {
      this.setTransactionIsLoading(false);
      return;
    }
    this.transaction = response;
    this.setTransactionIsLoading(false);
  };

  deleteTransactionConfirm = (onConfirm?: () => void) => {
    showConfirmModal({
      title: "Удалить денежную операцию?",
      confirmButtonLabel: "Удалить",
      onConfirm: () => this.fetchDeleteTransaction().then(onConfirm),
      minWidth: "350px",
    });
  };

  fetchDeleteTransaction = async () => {
    if (!this.transaction) return;
    const response = await deleteMoneyTransaction(this.transaction.id);
    if (!response) return;
    this.setTransaction(response);
  };

  fetchGetTransactionStatuses = async () => {
    const response = await getTransactionStatuses();
    if (!response) return;

    this.statuses = response;
  };

  fetchGetTransactionTypes = async () => {
    const response = await getTransactionTypes();
    if (!response) return;

    this.types = response;
  };

  fetchGetTransactionPayTypes = async () => {
    const response = await getTransactionPaymentTypes();
    if (!response) return;
    this.payTypes = response;
  };

  fetchGetTransactionOperationTypes = async () => {
    const response = await getTransactionOperationTypes();
    if (!response) return;
    this.operationTypes = response;
  };

  fetchGetTransactionCashFlowArticles = async () => {
    const response = await getTransactionCashFlowArticles();
    if (!response) return;
    this.cashFlowArticles = response;
  };
  fetchGetContragents = async (searchValue?: string, page?: number) => {
    this.contragentsIsLoading = true;
    const response = await getDirectoryContragentsPaginated({
      per_page: 50,
      search: searchValue,
      page: page ?? this.contragentsMeta?.current_page,
    });
    if (!response) {
      this.contragentsIsLoading = false;
      return;
    }
    this.contragents =
      response.meta.current_page === 1
        ? response.data
        : [...toJS(this.contragents), ...response.data];
    this.contragentsMeta = response.meta;
    this.contragentsIsLoading = false;
  };

  fetchGetEmployees = async () => {
    const response = await getEmployees();
    if (!response) return;
    this.employees = response;
  };

  fetchGetBusinessHolders = async () => {
    const response = await getBusinessHolders();
    if (!response) return;
    this.businessHolders = response;
  };

  fetchGetPnLArticles = async () => {
    const response = await getPnLArticles();
    if (!response) return;
    this.pnlArticles = response;
  };

  fetchGetCommitments = async () => {
    const response = await getCommitments();
    if (!response) return;
    this.commitments = response;
  };

  fetchGetTaxes = async () => {
    const response = await getTaxes();
    if (!response) return;
    this.taxes = response;
  };

  fetchGetNeedleToPay = async () => {
    const response = await getNeedleToPay();
    if (!response) return [];
    return response;
  };

  fetchGetEmployeesAndHelpers = async () => {
    const response = await getEmployeesAndHelpers();
    if (!response) return;
    this.employeesAndHelpers = response;
  };
  fetchGetTransactionGuide = async () => {
    this.fetchGetTransactionCashFlowArticles();
    this.fetchGetTransactionOperationTypes();
    this.fetchGetTransactionPayTypes();
    this.fetchGetTransactionStatuses();
    this.fetchGetTransactionTypes();
    this.fetchGetPnLArticles();
    this.fetchGetCommitments();
    this.fetchGetTaxes();
  };
}
