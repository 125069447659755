import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { PeriodPicker } from "widgets/datepickers/period-picker";

export const PeriodFilterComponent = () => {
  const { RootStore } = useStore();
  const { MoneyTransactionsStore: BankStatementsStore } = RootStore;
  const { filterPeriodStart, filterPeriodEnd, handleFilterPeriodChange } = BankStatementsStore;

  return (
    <PeriodPicker
      periodStart={filterPeriodStart}
      periodEnd={filterPeriodEnd}
      periodSetter={handleFilterPeriodChange}
      isFilter
    />
  );
};

export const PeriodFilter = observer(PeriodFilterComponent);
