import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useMemo } from "react";
import cls from "./bep.module.scss";
import { CommonInfoTable } from "./common-info-table";
import { EmployeesTable } from "./employees-table";
import { useStore } from "store";
import { addSpaces } from "assets/utils";

//TODO: числа toFixed(2)

export const getDatesInMonth = (month: Dayjs) => {
  const start = month.startOf("month");
  const end = month.endOf("month");

  return Array.from({ length: end.diff(start, "days") + 1 }, (_, i) => start.add(i, "days"));
};

interface BepInformationProps {}

const BepInformationComponent: FC<BepInformationProps> = () => {
  const {
    RootStore: {
      SalaryFundStore: {
        setInfoMessage,
        selectedDate,
        bepInfo,
        tableBepItems,
        fetchGetBep,
        netOrGross,
      },
    },
  } = useStore();

  useEffect(() => {
    fetchGetBep();
  }, [selectedDate]);
  useEffect(() => {
    setInfoMessage(null);
  }, [setInfoMessage]);
  const days = getDatesInMonth(dayjs(selectedDate));

  const tablesData = useMemo(
    () =>
      Object.entries(tableBepItems).map(
        ([key, value]) =>
          value.length > 0 && (
            <tr key={key} className={cls["tr"]}>
              <td colSpan={days.length + 3}>
                <EmployeesTable days={days} employees={value} title={key} />
              </td>
            </tr>
          )
      ),
    [days, tableBepItems]
  );

  const approvedSum = useMemo(
    () =>
      bepInfo?.users.reduce((acc, user) => acc + user.approved[`approved_${netOrGross}`], 0) ?? 0,
    [bepInfo?.users, netOrGross]
  );

  return (
    <table className={cls["external-table"]}>
      <colgroup>
        <col width={365} />
        {days.map(d => (
          <col width={226} />
        ))}
        <col width={226} />
        <col width={113} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={`${cls["th"]} ${cls["bep-th"]}`}>
            <div className={cls["bep-info"]}>
              {" "}
              <span>ТБУ: {bepInfo ? addSpaces(bepInfo.bep) : "-"} руб. </span>
            </div>
          </th>
          {days.map(d => (
            <th className={cls["th"]}>
              <div>{d.locale("ru").format("DD MMMM")}</div>
            </th>
          ))}
          <th className={cls["th"]}>Всего за месяц</th>
          <th className={cls["th"]}>Утверждено</th>
        </tr>
      </thead>
      <tbody>
        <tr className={cls["tr"]}>
          <td colSpan={days.length + 3}>
            <CommonInfoTable days={days} />
          </td>
        </tr>
        {tablesData}
      </tbody>
      <tfoot className={cls["tfoot"]}>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}>Всего, ₽</td>
          <td className={cls["td"]} colSpan={days.length + 1}></td>
          <td className={cls["td"]}>{addSpaces(approvedSum.toFixed(2))}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export const BepInformation = observer(BepInformationComponent);
