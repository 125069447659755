import axios from "axios";
import {AxiosResponse} from "axios/index";
import {showAlertPopup} from "../ui/alert";
import Cookies from "js-cookie";
import {ShowAlertPopupType} from "../utils/const/shared";

export interface IBaseResponse<T> {
  data: T;
  success: boolean;
}

export interface IResponseWithErrors {
  success?: boolean;
  message: string;
  errors?: string[];
}

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/api/web/v1`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

export function updateAuthorizationHeader() {
  api.defaults.headers.Authorization = `Bearer ${Cookies.get("token")}`;
}

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.success === false && response.data.message) {
      showAlertPopup("Ошибка", `${response.data.message}`, ShowAlertPopupType.ERROR);
    }
    return response;
  },
  (error: any) => {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.dispatchEvent(new Event("storage"));
    } else {
      const alertFields = {
        title: "Упс! Что-то пошло не так",
        subtitle: "Обновите страницу или попробуйте позже.",
        type: ShowAlertPopupType.ERROR,
      };

      if (error.response.status === 403) {
        alertFields.title = "Ошибка прав доступа";
        alertFields.subtitle =
          "Не соответствие прав доступа, невозможно выполнить операцию";
      }

      showAlertPopup(alertFields.title, alertFields.subtitle, alertFields.type);
    }
    return Promise.reject(error);
  }
);
