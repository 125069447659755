import { FC, useMemo } from "react";
import cls from "./status-styles.module.scss";
import { InfoIcon } from "pages/salary-fund/icons";
import { Tooltip } from "ui-new/tooltip/tooltip";
import { IMoneyTransaction } from "types/money-transactions";
interface StatusProps {
  status: IMoneyTransaction["status_title"];
}

const StatusComponent: FC<StatusProps> = ({ status }) => {
  const isError = useMemo(() => (["Ошибка", "Проверить"] as any).includes(status), [status]);
  const isSuccess = useMemo(() => status === "Утверждено", [status]);

  return (
    <div className={cls["status-container"]}>
      <p
        className={cls["status"]}
        style={isError ? { color: "#EB5757" } : isSuccess ? { color: "#03A700" } : {}}
        title={status ?? ""}
      >
        {status}
      </p>
      {isError && (
        <Tooltip
          activator={
            <div className={cls["status-info-icon"]}>
              <InfoIcon color="#6D37F6" />
            </div>
          }
          text="Не удалось распознать операцию"
          placement="top"
        />
      )}
    </div>
  );
};

export const Status = StatusComponent;
