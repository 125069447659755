import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import {FC, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useStore} from 'store';
import SelectField from 'ui-new/select';
import {showAlertPopup} from 'ui/alert';
import {Modal} from 'widgets/modal';
import {ModalButtons} from 'widgets/modal/buttons';
import {ShowAlertPopupType} from "../../../../../utils/const/shared";

interface FormValues {
  helperId: number;
}

interface AddHelperProps {

}

const AddHelperModalComponent: FC<AddHelperProps> = () => {
  const { RootStore: { SchedulePlanningStore: { setAddHelperModalIsOpen,fetchGetEmployees,employees,fetchAddHelper,addHelperIsFetching } } } = useStore();
  const { handleSubmit,control } = useForm<FormValues>({});

  useEffect(() => {
    fetchGetEmployees();
  },[]);

  const options = useMemo(() => toJS(employees).map(emp => ({ label: emp.name,value: emp.id })),[toJS(employees)]);

  const onSave = (values: FormValues) => {
    if (!values.helperId) {
      onClose();
      showAlertPopup('Добавление отменено','Сотрудник не был выбран', ShowAlertPopupType.SUCCESS);
      return;
    }

    fetchAddHelper(values.helperId);
  };
  const onClose = () => setAddHelperModalIsOpen(false);

  return (<Modal
    title='Добавление хелпера'
    maxWidth='480px'
    minWidth='480px'
    onClose={onClose}
  >
    <div style={{ display: 'flex',flexDirection: 'column',gap: '13px' }}>
      <SelectField label='Выбери сотрудника' control={control} name={'helperId'} options={options}
        selectProps={{ placeholder: 'Выбери сотрудника',showSearch: true,optionFilterProp: 'label' }} />
      <div style={{ alignSelf: 'flex-end' }}>
        <ModalButtons onPrimaryButtonClick={handleSubmit(onSave)} onSecondaryButtonClick={onClose} primaryButtonDisabled={addHelperIsFetching}
          primaryButtonLabel={'Сохранить'} secondaryButtonLabel={'Отменить'} />
      </div>
    </div>
  </Modal>);
};

export const AddHelperModal = observer(AddHelperModalComponent);