import { makeAutoObservable } from "mobx";
import { getAuth } from "../../api/auth";
import { showAlertPopup } from "../../ui/alert";
import Cookies from "js-cookie";
import { updateAuthorizationHeader } from "../../api/api";
import { RootStore } from "../../store/rootStore";
import { ShowAlertPopupType } from "utils/const/shared";

export class AuthStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  isLoading: boolean = false;

  // Auth
  login: string = "";
  password: string = "";
  passwordRepeat: string = "";
  passwordVision: boolean = false;
  passwordRepeatVision: boolean = false;
  saveAuth: boolean = false;
  savePassword: boolean = false;

  // Onboarding
  onboardingStep1: boolean = false;
  onboardingStep2: boolean = false;
  onboardingStep3: boolean = false;

  allDaysStart: string = "";
  allDaysEnd: string = "";
  weekdaysStart: string = "";
  weekdaysEnd: string = "";
  weekendStart: string = "";
  weekendEnd: string = "";

  setLogin = (value: string) => {
    this.login = value;
  };
  setPassword = (value: string) => {
    this.password = value;
  };
  setPasswordRepeat = (value: string) => {
    this.passwordRepeat = value;
  };
  setPasswordVision = (bool: boolean) => {
    this.passwordVision = bool;
  };
  setPasswordRepeatVision = (bool: boolean) => {
    this.passwordRepeatVision = bool;
  };
  setSaveAuth = (bool: boolean) => {
    this.saveAuth = bool;
  };
  setSavePassword = (bool: boolean) => {
    this.savePassword = bool;
  };
  setIsLoading = (bool: boolean) => {
    this.isLoading = bool;
  };

  fetchAuth = async () => {
    this.setIsLoading(true);
    const response = await getAuth(this.login, this.password);
    this.setIsLoading(false);
    if (response.success) {
      if (this.saveAuth) {
        Cookies.set("token", response.data.token, {
          expires: new Date("9999-12-31T23:59:59"),
        });
      } else {
        Cookies.set("token", response.data.token);
      }
      updateAuthorizationHeader();
    } else {
      showAlertPopup("Ошибка", `${response.message}`, ShowAlertPopupType.ERROR);
      return Promise.reject(ShowAlertPopupType.ERROR);
    }
  };

  // Onboarding
  setOnboardingStep = (stepNum: number, bool: boolean) => {
    switch (stepNum) {
      case 1:
        this.onboardingStep1 = bool;
        break;
      case 2:
        this.onboardingStep2 = bool;
        break;
      case 3:
        this.onboardingStep3 = bool;
        break;
      default:
        break;
    }
  };

  setSpotTime = (period: string, time: string) => {
    switch (period) {
      case "allDaysStart":
        this.allDaysStart = time;
        break;
      case "allDaysEnd":
        this.allDaysEnd = time;
        break;
      case "weekdaysStart":
        this.weekdaysStart = time;
        break;
      case "weekdaysEnd":
        this.weekdaysEnd = time;
        break;
      case "weekendStart":
        this.weekendStart = time;
        break;
      case "weekendEnd":
        this.weekendEnd = time;
        break;
      default:
        break;
    }
  };
}
