import { observer } from "mobx-react-lite";
import { FC, useMemo, useState } from "react";
import cls from "./table-style.module.scss";
import { IHoldAndPaid, IHoldAndPaidTotal } from "../types";
import { addSpaces } from "assets/utils";
import { useStore } from "store";

interface EmployeesTableProps {
  employees: IHoldAndPaid[];
  title: string;
}

const EmployeesTableComponent: FC<EmployeesTableProps> = ({ employees, title }) => {
  const {
    RootStore: {
      SalaryFundStore: { netOrGross },
    },
  } = useStore();

  const [total, setTotal] = useState<IHoldAndPaidTotal>({
    approved_net: 0,
    approved_gross: 0,
    hold_by_inventory: 0,
    hold_by_ndfl: 0,
    hold_by_writeoffs: 0,
    needle: 0,
    needle_last_period: 0,
    needle_with_holds: 0,
    paid: 0,
  });

  const titleData = useMemo(() => {
    switch (title) {
      case "helpers":
        return "Хелперы";
      case "joe":
        return "Работники кухни";
      default:
        return "Работники бара";
    }
  }, [title]);

  const employeesData = useMemo(() => {
    const total: IHoldAndPaidTotal = {
      approved_net: 0,
      approved_gross: 0,
      hold_by_inventory: 0,
      hold_by_ndfl: 0,
      hold_by_writeoffs: 0,
      needle: 0,
      needle_last_period: 0,
      needle_with_holds: 0,
      paid: 0,
    };
    const employeesData = employees.map((employee, index) => {
      total.approved_net += employee[`approved_${netOrGross}`];
      total.hold_by_inventory += employee.hold_by_inventory;
      total.hold_by_ndfl += employee.hold_by_ndfl;
      total.hold_by_writeoffs += employee.hold_by_writeoffs;
      total.needle += employee.needle;
      total.needle_last_period += employee.needle_last_period;
      total.needle_with_holds += employee.needle_with_holds;
      total.paid += employee.paid;
      return <EmployeeRow key={employee.id} employee={employee} index={index + 1} />;
    });
    setTotal(total);
    return employeesData;
  }, [employees, netOrGross]);

  const totalData = useMemo(() => {
    const {
      approved_net,
      hold_by_inventory,
      hold_by_ndfl,
      hold_by_writeoffs,
      needle,
      needle_last_period,
      needle_with_holds,
      paid,
    } = total;
    return (
      <tr className={`${cls["tr"]}`}>
        <td className={cls["td"]}></td>
        <td className={cls["td"]}>Всего, ₽</td>
        <td className={cls["td"]}>{addSpaces((needle_last_period ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((approved_net ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((hold_by_ndfl ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((hold_by_inventory ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((hold_by_writeoffs ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((needle_with_holds ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((paid ?? 0).toFixed(2))}</td>
        <td className={cls["td"]}>{addSpaces((needle ?? 0).toFixed(2))}</td>
      </tr>
    );
  }, [total]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={44} />
        <col width={230} />
        <col width={184} />
        <col width={205} />
        <col width={122} />
        <col width={169} />
        <col width={140} />
        <col width={224} />
        <col width={108} />
        <col width={158} />
      </colgroup>
      <thead className={cls["thead"]}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]}>№</th>
          <th className={cls["th"]}>{titleData}</th>
          <th className={cls["th"]}>Долг предыдущ. периода, ₽</th>
          <th className={cls["th"]}>Начислено в текущ. периоде, ₽</th>
          <th className={cls["th"]}>НДФЛ, ₽</th>
          <th className={cls["th"]}>Удержания по инвенте, ₽</th>
          <th className={cls["th"]}>Удержания по iiko, ₽</th>
          <th className={cls["th"]}>К выплате с учетом удержаний, ₽</th>
          <th className={cls["th"]}>Выплачено, ₽</th>
          <th className={cls["th"]}>Осталось выплатить, ₽</th>
        </tr>
      </thead>
      <tfoot className={cls["tfoot"]}>{totalData}</tfoot>
      <tbody className={cls["tbody"]}>{employeesData}</tbody>
    </table>
  );
};

interface EmployeeRowProps {
  employee: IHoldAndPaid;
  index: number;
}

const EmployeeRowComponent: FC<EmployeeRowProps> = ({
  employee: {
    user,
    user_job_title,
    approved_net,
    approved_gross,
    hold_by_inventory,
    hold_by_ndfl,
    hold_by_writeoffs,
    needle,
    needle_last_period,
    needle_with_holds,
    paid,
    period,
    is_helper,
  },
  index,
}) => {
  const {
    RootStore: {
      SalaryFundStore: { netOrGross },
    },
  } = useStore();

  return (
    <tr className={`${cls["tr"]} ${cls["employee"]}`}>
      <td className={cls["td"]}>{index}</td>
      <td className={cls["td"]}>
        <div className={cls["employee-name"]}>
          <span className={cls["name"]} title={user}>
            {user}
          </span>{" "}
          <span className={cls["position"]}>{is_helper ? "Хэлпер" : user_job_title}</span>
        </div>
      </td>
      <td className={cls["td"]}>{addSpaces((needle_last_period ?? 0).toFixed(2))}</td>
      <td className={cls["td"]}>
        {addSpaces(((netOrGross === "gross" ? approved_gross : approved_net) ?? 0).toFixed(2))}
      </td>
      <td className={cls["td"]}>{addSpaces((hold_by_ndfl ?? 0).toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces((hold_by_inventory ?? 0).toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces((hold_by_writeoffs ?? 0).toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces((needle_with_holds ?? 0).toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces((paid ?? 0).toFixed(2))}</td>
      <td className={cls["td"]}>{addSpaces((needle ?? 0).toFixed(2))}</td>
    </tr>
  );
};

const EmployeeRow = observer(EmployeeRowComponent);

export const EmployeesTable = observer(EmployeesTableComponent);
