import styles from "./styles.module.scss";
import {Button} from "ui-new/button";
import {useMemo} from "react";
import {WarningIcon} from "../../icons/Warning";
import {SuccessIcon} from "../../icons/Success";

export interface Props {
    item: any;
    index: number;
    openModal: () => void;
    isMonthAccepted: boolean;
    isSubmitDisabled: boolean;
    onMonthClose: () => Promise<void>;
    isFirstRequest: boolean;
}

export function ListItem ({ item, index, openModal, isMonthAccepted, isSubmitDisabled, onMonthClose, isFirstRequest }: Props) {

    const buttonLabel = useMemo(() => {
        if (item.isLoading) return 'Идет проверка';
        else if (item.value === 'accepts') return 'Подтвердить';
        else return 'Готово'
    }, [item])

    const handleClick = async () => {
        if (item.value === 'accepts') openModal();
        if (item.value === 'month-close') await onMonthClose();
    }

    const mainButtonDisabled = useMemo(() => {
        if (item.value === 'month-close') return !isMonthAccepted || isSubmitDisabled;
        if (item.value === 'accepts') return false;
    }, [item, isMonthAccepted, isSubmitDisabled]);

    const showButton = useMemo(() => {
        return item.value && !item.success && !item.message && (item.value === 'month-close' || (item.value === 'accepts' && !isMonthAccepted))
    }, [item, isMonthAccepted]);

    return (
        <li className={styles.listItem}>
            <div className={styles.listItemIndex}>
                {index + 1}
            </div>

            <div className={styles.listItemTitle}>
                <span className={styles.listItemTitleText}>
                    {item.title}
                </span>

                {item?.message && isFirstRequest &&
                    <span className={styles.listItemSubtitle}>
                        {item?.errorMessage}
                    </span>
                }
            </div>

            {!item.success && item.message && isFirstRequest &&
                <div className={styles.failed}>
                    <WarningIcon className={styles.icon} />
                    <span>
                        Есть замечания
                    </span>
                </div>
            }
            {item.success &&
                <div className={styles.successfully}>
                    <SuccessIcon className={styles.successIcon} />
                    <span>Успешно</span>
                </div>
            }
            {item.value === 'accepts' && isMonthAccepted &&
                <div className={styles.accepted}>
                    <SuccessIcon className={styles.successIcon}/>
                    <span>Подтверждено</span>
                </div>
            }
            {showButton &&
                <Button onClick={handleClick} className={styles.listItemButton} isLoading={item?.isLoading} disabled={mainButtonDisabled}>
                    {buttonLabel}
                </Button>
            }
        </li>
    )
}