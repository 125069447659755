import React, { FC, useEffect, useState } from "react";
import PnlReportRow from "./pnl-report-row";
import { PnlReportRowDataTest } from "../types";
import { getPnlData } from "api/pnl";

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

interface PnlReportTableProps {
  date: Date;
}

const PnlReportTable: FC<PnlReportTableProps> = ({ date }) => {
  const [tableDataFetched, setTableDataFetched] = useState<PnlReportRowDataTest[]>([]);

  // Загрузить данные при монтировании компонента (или по кнопке)
  useEffect(() => {
    const period = date.getFullYear().toString();
    getPnlData(period).then(response => {
      if (response) {
        setTableDataFetched(response);
      } else {
        setTableDataFetched([]);
      }
    });
  }, [date]);

  return (
    <table className="report-table">
      <colgroup>
        <col width={300} />
        {months.map(() => (
          <>
            <col width={140} />
            <col width={140} />
          </>
        ))}
        <col width={140} />
        <col width={140} />
      </colgroup>
      <thead className="tableHeader">
        <tr>
          <th rowSpan={2} className="title">
            Разделы
          </th>
          {months.map(month => (
            <th colSpan={2} className="monthCell">
              {month}
            </th>
          ))}
          <th colSpan={2} className="monthCell">
            Итого
          </th>
        </tr>
        <tr className="subHeader">
          {months.map(() => (
            <>
              <th className="planCell">План</th>
              <th className="factCell">Факт</th>
            </>
          ))}
          <th className="planCell">План</th>
          <th className="factCell">Факт</th>
        </tr>
      </thead>
      <tbody>
        {tableDataFetched.map((row, index) => (
          <PnlReportRow key={index} data={row} isCategory={!row.is_indicator} />
        ))}
      </tbody>
    </table>
  );
};

export default PnlReportTable;
