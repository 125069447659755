import React, {ChangeEvent, memo, useEffect, useMemo, useState} from 'react';
import "pages/registry-document/customSelectOption.scss";
import "../ManualInputTable/ManualInputTable.scss";
import classNames from "classnames";
import type {ManualOperationAccount, Transaction} from "../../index";
import {Select, Tooltip} from "antd";
import {emptyTransaction, subcontoEmptyData, subcontoTypesData} from "../../../const";
import {Undefinable} from "assets/types";
import {showAlertPopup} from "ui/alert";
import {getAccountSubkontoOfManualOperations, getSubkontoListOfManualOperations} from "api/operationInput";
import {NomenclatureType} from "../../../../registry-document/types";
import type {SubcontoType} from "../../../types";
import {isDeepEqual} from "../../../../../assets/utils";
import {isArray} from "lodash";
import {ShowAlertPopupType} from "../../../../../utils/const/shared";

interface Props {
    transaction: Transaction;
    accounts: ManualOperationAccount[];
    accountsLoading: boolean;
    updateRequestBody: (transactionBody: TransactionRequest, index: number) => void;
    index: number;
}

export interface TransactionRequest {
    amount: string;
    credit_account: string;
    debit_account: string;
    debit_quantity: number;
    credit_quantity: number;
    row_number: number;
    credit_subconto: SubcontoRequestData[];
    debit_subconto: SubcontoRequestData[];
}

interface SubcontoRequestData {
    subconto_type: string;
    subconto_value: string;
}

interface SubcontoData {
    debit: SubcontoType[][];
    credit: SubcontoType[][];
}

interface SubcontoTypeData {
    name: string;
    label: string;
}

export type SubcontosTypeData = {
    debit: SubcontoTypeData[],
    credit: SubcontoTypeData[]
}

type InputKey = Extract<keyof TransactionRequest, "amount" | "debit_quantity" | "credit_quantity">;
type SelectKey = Extract<keyof TransactionRequest, "credit_account" | "debit_account">;
type SubkontoKey = Extract<keyof TransactionRequest, "credit_subconto" | "debit_subconto">;

const filterSelectOptions = (input: string, option: Undefinable<ManualOperationAccount>) => {
    return (option?.label ?? "")
        .toLowerCase()
        .includes(input.toLowerCase())
};

const filterSubcontoOptions = (input: string, option: any) => {
    return (typeof option?.label === 'string' ? option.label.toLowerCase() : '')
        .includes(input.toLowerCase())
};

const TheManualInputTableRow = (props: Props) => {
    const {
        transaction,
        accounts,
        accountsLoading,
        updateRequestBody,
        index,
    } = props;

    const [transactionBody, setTransactionBody] = useState<TransactionRequest>({
        ...emptyTransaction,
        row_number: index + 1,
    });
    const [subcontoData, setSubcontoData] = useState<SubcontoData>({...subcontoEmptyData});
    const [isSubcontoLoading, setSubcontoLoading] = useState(false);
    const [subcontoTypeList, setSubcontoTypeList] = useState<SubcontosTypeData>({...subcontoTypesData});

    // helpers
    const getToolTipLabel = (option: SubcontoType) => {
        return (
            <div className="select-tooltip-container">
                <p className="select-tooltip-item select-tooltip-item-label">{option.work_name} <br/></p>
                <p className="select-tooltip-item">{option?.path || ""} <br/></p>
                <p className="select-tooltip-item">{option?.category || ""} <br/></p>
            </div>
        );
    }

    const getInputValidationRule = (type: InputKey) => {
        if (type.includes('quantity')) {
            return /^\d+(\.\d{0,3})?$/;
        } else {
            return /^\d+(\.\d{0,2})?$/;
        }
    }

    // input handlers
    const onInputHandler = (e: ChangeEvent, key: InputKey) => {
        const target = e.target as HTMLInputElement;
        const value: string = target.value;
        const rule: RegExp = getInputValidationRule(key);

        if (value === "") {
            setTransactionBody(prevState => ({ ...prevState, [key]: value }));
            return;
        } else if (!rule.test(value)) return;

        setTransactionBody(prevState => ({ ...prevState, [key]: value }));
    }

    const onSelect = async (e: string, key: SelectKey) => {
        setSubcontoLoading(true);
        setTransactionBody(prevState => ({ ...prevState, [key]: e }));

        const isCredit = key.includes("credit");

        try {
            const subcontoTypes = await getAccountSubkontoOfManualOperations(e);
            const subcontoKey = isCredit ? 'credit' : 'debit';

            setSubcontoTypeList(prevState => ({...prevState, [subcontoKey]: [...subcontoTypes.data]}));

            const response = await Promise.all(
                subcontoTypes.data.map(async (subconto: { name: string, label: string }) => {
                    const {name} = subconto;
                    const data = await getSubkontoListOfManualOperations(name);
                    return data.data;
                })
            );

            setSubcontoData(prevState => ({...prevState, [subcontoKey]: [...response]}));
        } catch (e) {
            showAlertPopup("Возникли проблемы", "Проблемы с загрузкой субконто", ShowAlertPopupType.ERROR);
        } finally {
            setSubcontoLoading(false);
        }
    }

    const onSelectSubconto = (guid: string, key: SubkontoKey, index: number) => {
        const subcontoTypeKey = key.split("_")[0] as 'debit' | 'credit';
        const type = subcontoTypeList[subcontoTypeKey][index];
        const newSubconto = {subconto_value: guid, subconto_type: type.name};
        const temp: Pick<TransactionRequest, 'debit_subconto' | 'credit_subconto'> = { debit_subconto: [], credit_subconto: [] };

        if (isArray(transactionBody[key]) && !transactionBody[key].length) {
            temp[key] = [{...newSubconto}];
        } else {
            temp[key].push({...newSubconto});
        }

        setTransactionBody((prev) => ({
            ...prev,
            credit_subconto: temp.credit_subconto,
            debit_subconto: temp.debit_subconto,
        }));
    };

    // memoized
    const subcontoDataDebitFirst = useMemo(() =>
        subcontoData.debit[0] &&
        <Select
            className={"nameSelect"}
            loading={isSubcontoLoading}
            showSearch
            placeholder="Субконто 1"
            optionFilterProp="children"
            onSearch={() => {
            }}
            onChange={(option) => onSelectSubconto(option, "debit_subconto", 0)}
            filterOption={filterSubcontoOptions}
        >
            {subcontoData.debit[0]?.map((option: SubcontoType) => (
                <Select.Option
                    key={option.guid}
                    value={option.guid}
                    label={option.work_name}
                >
                    <div className="select-option">
                        <Tooltip title={getToolTipLabel(option)}>
                        <span className="select-option-main-label">
                            {option.work_name}
                        </span>
                        </Tooltip>
                        <span className="select-option-type-label">
                            {NomenclatureType[option.type]}
                        </span>
                    </div>
                </Select.Option>
            ))}
        </Select>
        || "Субконто 1", [subcontoData.debit[0], isSubcontoLoading]);

    const subcontoDataCreditFirst = useMemo(() =>
            subcontoData.credit[0] &&
            <Select
                className={"nameSelect"}
                loading={isSubcontoLoading}
                showSearch
                placeholder="Субконто 1"
                optionFilterProp="children"
                onSearch={() => {
                }}
                onChange={(option) => onSelectSubconto(option, "credit_subconto", 0)}
                filterOption={filterSubcontoOptions}
            >
                {subcontoData.credit[0].map((option: SubcontoType) => (
                    <Select.Option
                        key={option.guid}
                        value={option.guid}
                        label={option.work_name}
                    >
                        <div className="select-option">
                            <Tooltip title={getToolTipLabel(option)}>
                                <span className="select-option-main-label">
                                    {option.work_name}
                                </span>
                            </Tooltip>
                            <span className="select-option-type-label">
                                {NomenclatureType[option.type]}
                            </span>
                        </div>
                    </Select.Option>
                ))}
            </Select>
            || "Субконто 1", [subcontoData.credit[0], isSubcontoLoading]);

    const subcontoDataDebitSecond = useMemo(() =>
        subcontoData.debit[1] &&
        <Select
            className={"nameSelect"}
            loading={isSubcontoLoading}
            showSearch
            placeholder="Субконто 2"
            optionFilterProp="children"
            onSearch={() => {
            }}
            onChange={(option) => onSelectSubconto(option, "debit_subconto", 1)}
            filterOption={filterSubcontoOptions}
        >
            {subcontoData.debit[1]?.map((option: SubcontoType) => (
                <Select.Option
                    key={option.guid}
                    value={option.guid}
                    label={option.work_name}
                >
                    <div className="select-option">
                        <Tooltip title={getToolTipLabel(option)}>
                        <span className="select-option-main-label">
                            {option.work_name}
                        </span>
                        </Tooltip>
                        <span className="select-option-type-label">
                            {NomenclatureType[option.type]}
                        </span>
                    </div>
                </Select.Option>
            ))}
        </Select>
        || "Субконто 2", [subcontoData.debit[1], isSubcontoLoading]);

    const subcontoDataCreditSecond = useMemo(() =>
        subcontoData.credit[1] &&
        <Select
            className={"nameSelect"}
            loading={isSubcontoLoading}
            showSearch
            placeholder="Субконто 2"
            optionFilterProp="children"
            onSearch={() => {
            }}
            onChange={(option) => onSelectSubconto(option, "credit_subconto", 1)}
            filterOption={filterSubcontoOptions}
        >
            {subcontoData.credit[1].map((option: SubcontoType) => (
                <Select.Option
                    key={option.guid}
                    value={option.guid}
                    label={option.work_name}
                >
                    <div className="select-option">
                        <Tooltip title={getToolTipLabel(option)}>
                                <span className="select-option-main-label">
                                    {option.work_name}
                                </span>
                        </Tooltip>
                        <span className="select-option-type-label">
                                {NomenclatureType[option.type]}
                            </span>
                    </div>
                </Select.Option>
            ))}
        </Select>
        || "Субконто 2", [subcontoData.credit[1], isSubcontoLoading]);

    const subcontoDataDebitThird = useMemo(() =>
        subcontoData.debit[2] &&
        <Select
            className={"nameSelect"}
            loading={isSubcontoLoading}
            showSearch
            placeholder="Субконто 3"
            optionFilterProp="children"
            onSearch={() => {
            }}
            onChange={(option) => onSelectSubconto(option, "debit_subconto", 2)}
            filterOption={filterSubcontoOptions}
        >
            {subcontoData.debit[2]?.map((option: SubcontoType) => (
                <Select.Option
                    key={option.guid}
                    value={option.guid}
                    label={option.work_name}
                >
                    <div className="select-option">
                        <Tooltip title={getToolTipLabel(option)}>
                        <span className="select-option-main-label">
                            {option.work_name}
                        </span>
                        </Tooltip>
                        <span className="select-option-type-label">
                            {NomenclatureType[option.type]}
                        </span>
                    </div>
                </Select.Option>
            ))}
        </Select>
        || "Субконто 3", [subcontoData.debit[2], isSubcontoLoading]);

    const subcontoDataCreditThird = useMemo(() =>
        subcontoData.credit[2] &&
        <Select
            className={"nameSelect"}
            loading={isSubcontoLoading}
            showSearch
            placeholder="Субконто 3"
            optionFilterProp="children"
            onSearch={() => {
            }}
            onChange={(option) => onSelectSubconto(option, "credit_subconto", 2)}
            filterOption={filterSubcontoOptions}
        >
            {subcontoData.credit[2].map((option: SubcontoType) => (
                <Select.Option
                    key={option.guid}
                    value={option.guid}
                    label={option.work_name}
                >
                    <div className="select-option">
                        <Tooltip title={getToolTipLabel(option)}>
                            <span className="select-option-main-label">
                                {option.work_name}
                            </span>
                        </Tooltip>
                        <span className="select-option-type-label">
                            {NomenclatureType[option.type]}
                        </span>
                    </div>
                </Select.Option>
            ))}
        </Select>
        || "Субконто 3", [subcontoData.credit[2], isSubcontoLoading]);

    useEffect(() => {
        updateRequestBody(transactionBody, index);
    }, [transactionBody]);

    return (
        <tr>
            <td colSpan={6}>
                <table className="table">
                    <tbody>
                    <tr className="bodyRow">
                        <td className={classNames("bodyColumn", "numberColumn")}>
                            <span className="rowNumberText">{transaction.index}</span>
                        </td>
                        <td className={classNames("bodyColumn", "nameColumn")}>
                            <Select
                                className={"nameSelect"}
                                onChange={async (e) => await onSelect(e, "debit_account")}
                                loading={accountsLoading}
                                showSearch
                                placeholder="Выберите счет"
                                optionFilterProp="children"
                                options={accounts}
                                onSearch={() => {
                                }}
                                filterOption={filterSelectOptions}
                            />
                        </td>
                        <td className={classNames("bodyColumn", "countColumn")}>
                            <input
                                onChange={(e) => onInputHandler(e, 'debit_quantity')}
                                type="text"
                                className={"countInput"}
                                placeholder="Кол-во"
                                value={transactionBody.debit_quantity || ""}
                            />
                        </td>
                        <td className={classNames("bodyColumn", "nameColumn")}>
                            <Select
                                className={"nameSelect"}
                                onChange={async (e) => await onSelect(e, "credit_account")}
                                loading={accountsLoading || isSubcontoLoading}
                                showSearch
                                placeholder="Выберите счет"
                                optionFilterProp="children"
                                options={accounts}
                                onSearch={() => {
                                }}
                                filterOption={filterSelectOptions}
                            />
                        </td>
                        <td className={classNames("bodyColumn", "countColumn", "disabledCell")}>
                            <input
                                onChange={(e) => onInputHandler(e, 'credit_quantity')}
                                type="text"
                                className={"countInput"}
                                placeholder="0"
                                value={transactionBody.credit_quantity || ""}
                                disabled={true}
                            />
                        </td>
                        <td className={classNames("bodyColumn", "sumColumn")}>
                            <input
                                onChange={(e) => onInputHandler(e, 'amount')}
                                type="text"
                                className={"countInput"}
                                placeholder="Сумма"
                                value={transactionBody.amount || ""}
                            />
                        </td>
                    </tr>
                    <tr className={"subkontoRow"}>
                        <td colSpan={6}>
                            <table className={"subkontoTable"}>
                                <tbody>
                                <tr>
                                    <td className={classNames("subkontoCell", "subkontoCellFirst")}>
                                        {subcontoDataDebitFirst}
                                    </td>
                                    <td className={classNames("subkontoCell", "subkontoCellSecond")}>
                                        {subcontoDataCreditFirst}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classNames("subkontoCell", "subkontoCellFirst")}>
                                        {subcontoDataDebitSecond}
                                    </td>
                                    <td className={classNames("subkontoCell", "subkontoCellSecond")}>
                                        {subcontoDataCreditSecond}
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classNames("subkontoCell", "subkontoCellFirst")}>
                                        {subcontoDataDebitThird}
                                    </td>
                                    <td className={classNames("subkontoCell", "subkontoCellSecond")}>
                                        {subcontoDataCreditThird}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    );
};

export const ManualInputTableRow = memo(
    TheManualInputTableRow, (prev, next) => isDeepEqual(prev, next)
);