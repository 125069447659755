import {
  IMoneyTransactionCashFlowArticle,
  IMoneyTransactionOperationType,
} from "types/money-transactions";
import { IBaseResponse } from "./api";
import { api } from "./api-new";
import { IBusinessHolder, IContragent, IPnLArticleTree } from "types/directories";
import { onError } from "./schedulePlanning";

export async function getBusinessHolders() {
  try {
    const response = await api.get<IBaseResponse<IBusinessHolder[]>>(
      "directories/money_transactions/business_holders"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getOperationTypes() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionOperationType[]>>(
      "directories/money_transactions/operation_types"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getCashFlowArticles() {
  try {
    const response = await api.get<IBaseResponse<IMoneyTransactionCashFlowArticle[]>>(
      "directories/money_transactions/cash_flow_articles"
    );
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export interface IGetContragentsFilter {
  //По title и inn
  search?: string | null;
  //Дефолт 1
  page?: number | null;
  //Дефолт 100
  per_page?: number | null;
}

export interface IContragentMetaProps {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
}

export async function getDirectoryContragentsPaginated(params?: IGetContragentsFilter) {
  try {
    const response = await api.get<
      IBaseResponse<{ data: IContragent[]; meta: IContragentMetaProps }>
    >("directories/contragents/paginated", { params });
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getDirectoryContragents() {
  try {
    const response = await api.get<IBaseResponse<IContragent[]>>("directories/contragents");
    return response.data.data;
  } catch (error) {
    return null;
  }
}

export async function getPnLTree(parentId?: IPnLArticleTree["id"]) {
  try {
    const response = await api.get<IBaseResponse<IPnLArticleTree[]>>("directories/pnl_articles", {
      params: { parent_id: parentId },
    });
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}

export async function getAccessPeriod() {
  try {
    const response = await api.get<IBaseResponse<{ period_start: string; period_end: string }>>(
      "month/getAccessPeriod"
    );
    return response.data.data;
  } catch (error) {
    return onError(error);
  }
}
