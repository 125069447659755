import { ValidationMessages, ValidationTypes } from "assets/utils/validationMessages";
import dayjs from "dayjs";
import * as yup from "yup";
import { MIN_DATE } from "../components/date-and-number";

export const actSchema = yup.object({
  number: yup
    .string()
    .max(12, ({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max} символов`)
    .test("no-only-spaces", ValidationMessages.get(ValidationTypes.required) as string, value =>
      value ? value.trim().length > 0 : false
    )
    .required(ValidationMessages.get(ValidationTypes.required)),
  date: yup
    .date()
    .typeError(`Выберите дату от 01.12.2024`)
    .test("date-range", `Выберите дату от 01.12.2024`, function (value) {
      if (!value) return false; // Если нет даты, тест проваливается
      const isAfterMin =
        dayjs(value).isAfter(dayjs(MIN_DATE), "day") || dayjs(MIN_DATE).isSame(dayjs(value), "day");
      return isAfterMin;
    })
    .required(ValidationMessages.get(ValidationTypes.required)),
  contragent_id: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required)),
  pnl_article_id: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .required(ValidationMessages.get(ValidationTypes.required)),
  sum: yup
    .number()
    .typeError(ValidationMessages.get(ValidationTypes.required) as string)
    .min(0.01, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
    .required(ValidationMessages.get(ValidationTypes.required))
    .nullable()
    .test("sum-or-positions", "Поле обязательное", function (sum) {
      const { positions } = this.parent;
      if ((!positions || positions.length === 0) && !sum) {
        return false;
      }
      return true;
    }),
  positions: yup
    .array()
    .of(
      yup.object({
        pnl_article_id: yup.number().required(),
        sum: yup
          .number()
          .min(0.01, ({ min }) => ValidationMessages.get(ValidationTypes.min) + ` ${min}`)
          .required(),
        act_service_id: yup.number().required(),
      })
    )
    .test("positions-or-sum", "Заполните позиции", function (positions) {
      const { sum } = this.parent;
      if ((!positions || positions.length === 0) && !sum) {
        return false;
      }
      return true;
    })
    .nullable(),
});
