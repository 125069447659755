import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import {getLoanRecord, refundLoan} from 'api/loan';
import {useNavigate, useParams} from "react-router";
import {DocumentHeader, type HeaderItem} from "ui-new/document-header";
import {DataTable, type DataTableHeader, type TableItem} from "ui-new/table";
import {status} from "utils/const/loan/status";
import dayjs from "dayjs";
import {Button} from "ui-new/button";
import {showAlertPopup} from "ui/alert";
import {headerTitles, tableHeaders} from "utils/const/loan/edit-loan";
import type {Loan, LoanPosition} from "../../types/loan";
import {ConfirmModal} from "../../widgets/modal/confirm-modal";
import {ShowAlertPopupType} from "../../utils/const/shared";

export const EditLoan = () => {
    const { loanId } = useParams();
    const navigate = useNavigate();

    const [loan, setLoan] = useState<Loan | null>(null);
    const [isLoanLoading, setLoanLoading] = useState<boolean>(false);
    const [isConfirmModal, setConfirmModal] = useState(false);

    const getHeaderDataValue = (key: string, value: string | number) => {
        switch (key) {
            case 'date':
                return dayjs(value).format('DD.MM.YYYY');
            case 'status':
                return status[value];
            case 'sum':
                return new Intl.NumberFormat('ru-RU').format(Number(value));
            default:
                return value;
        }
    }

    const headerData = () => {
        if (!loan) return [];

        const result: HeaderItem[] = [];

        Object.keys(loan).forEach((key) => {
            if (!headerTitles[key]) return;

            const element = {
                ...headerTitles[key],
                // @ts-ignore
                value: getHeaderDataValue(key, loan[key]),
            };

            result.push(element);
        });

        return result;
    }

    const getTableHeaders = () => {
        return tableHeaders.map((header) => ({ ...header, textAlign: 'left' })) as DataTableHeader<LoanPosition>[];
    }

    const onLoanHandler = async () => {
        try {
            const response: any = await refundLoan({
                ...loan,
                // @ts-ignore
                date: dayjs(loan.date).format('YYYY-MM-DD'),
            } as Loan);

            if (response?.response) {
                showAlertPopup('Не удалось утвердить займ', response?.response?.data?.message, ShowAlertPopupType.ERROR)
            } else {
                navigate('/main/loan');
                showAlertPopup('Сохранено!', 'Займ утвержден', ShowAlertPopupType.SUCCESS)
            }

        } catch (e) {
        } finally {
            setConfirmModal(false);
        }
    }

    const onDeleteHandler = (itemId: number | string) => {
        // @ts-ignore
        setLoan((prev) => ({
            ...prev,
            positions:  prev?.positions.filter((item) => item.id !== itemId)
        }));
    }

    const tableCell = (item: any, header: any) => {
        if (header.value === 'sum' || header.value === 'cost') {
            return <span className={styles.cellText}>
                    {new Intl.NumberFormat('ru-RU').format(item[header.value])}
                </span>
        }

        return <span className={styles.cellText}>
                    {item[header.value]}
                </span>
    }

    const getLoanPositions = (): TableItem<LoanPosition>[] => {
        if (!loan?.positions) return [];
        return loan?.positions.filter((position) => position.status === 'open');
    }

    useEffect(() => {
        setLoanLoading(true);

        getLoanRecord({ id: Number(loanId) })
            .then(res => setLoan(res))
            .catch(err => { return; })
            .finally(() => setLoanLoading(false));
    }, []);

    return (
        <div className={styles.page}>
            <div className={styles.pageHeader}>
                <div className={styles.pageHeaderText}>Расходная накладная</div>
                <Button onClick={() => setConfirmModal(true)} className={styles.headerButton}>Вернуть</Button>
            </div>

            <DocumentHeader isLoading={isLoanLoading} items={headerData()} />

            <DataTable
                onDelete={onDeleteHandler}
                headers={getTableHeaders()}
                items={getLoanPositions()}
                itemKey={'id'}
                isLoading={isLoanLoading}
                children={(item, header) => tableCell(item, header)}
            />

            {isConfirmModal && <ConfirmModal
                onClose={() => setConfirmModal(false)}
                onConfirm={onLoanHandler}
                title='Закрыть?'
                messages={['Вы точно уверены, что хотите вернуть продукцию?']}
                confirmButtonLabel='Да'
            />}
        </div>
    );
};
