import { FC, useCallback, useEffect, useMemo, useState } from "react";
import cls from "../styles.module.scss";
import { ActServiceHeader } from "./header";
import { useLocation, useNavigate } from "react-router";
import { Form } from "./form";
import { FormProvider, useForm } from "react-hook-form";
import { useStore } from "store";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { actSchema } from "./validation";
import { observer } from "mobx-react-lite";
import { BigSkeleton } from "ui";
import { IActServiceNewPosition } from "../types";
import { showNotificationModal } from "ui-new/alert";

export interface FormValues {
  //информационное поле
  responsible?: string;

  //для создания/редактирования акта
  number: string;
  date: Date;
  contragent_id: number;

  //для создания/изменения позиций (не считая ком. платежей)
  pnl_article_id: number;
  sum: number | null;

  positions?: (IActServiceNewPosition & { pnl_article_title?: string })[] | null;
}

interface ActServicesProps {}

const ActServicesComponent: FC<ActServicesProps> = () => {
  const {
    RootStore: {
      HeaderStore: { profileData },
      RegistryServicesDocumentStore: {
        act,
        setAct,
        setActIsLoading,
        setDocType,
        actIsLoading,
        fetchGetAct,
        fetchGetContragents,
        fetchGetPnLArticles,
        fetchCreateActWithPositions,
        fetchUpdateActWithPositions,
        approveStatusConfirm,
        period,
        utilityArticle,
      },
    },
  } = useStore();

  const navigate = useNavigate();
  const location = useLocation();
  const [isCreate, setIsCreate] = useState(false);

  useEffect(() => {
    setDocType("act_service");
    const id = location.hash.slice(1);
    if (id.length === 0) {
      setIsCreate(true);
      return;
    }
    setIsCreate(false);
    if (isNaN(Number(id))) {
      return;
    }
    fetchGetAct(Number(id));

    return () => {
      setDocType(null);
      setAct(null);
      setIsCreate(false);
    };
  }, [location.hash]);

  useEffect(() => {
    fetchGetContragents();
    fetchGetPnLArticles();
  }, [fetchGetContragents, fetchGetPnLArticles]);

  useEffect(() => {
    if (!act) {
      reset();
      return;
    }
    const defValues: FormValues = {
      contragent_id: act.contragent_id,
      date: act.date as unknown as Date,
      number: act.number,
      pnl_article_id: act.pnl_article_id as number,
      sum: null,
      responsible: act.responsible,
    };
    if (utilityArticle && act.pnl_article_id === utilityArticle.id) {
      defValues.pnl_article_id = act.pnl_article_id as number;
      defValues.positions = act.positions as any[];
    } else {
      defValues.pnl_article_id = act.positions[0].pnl_article_id;
      defValues.sum = act.positions[0].sum;
    }
    reset(defValues);
  }, [act, utilityArticle]);

  const onCreate = useCallback(
    ({ contragent_id, date, number, pnl_article_id, positions, sum }: FormValues) => {
      const position =
        (positions as IActServiceNewPosition[]) ??
        ({
          act_service_id: -1,
          pnl_article_id,
          sum: sum ?? 0,
        } as IActServiceNewPosition);

      fetchCreateActWithPositions(
        { contragent_id, date: dayjs(date).format("YYYY-MM-DD"), number },
        position
      ).then(act => navigate(`.#${act?.id}`));
    },
    [fetchCreateActWithPositions]
  );

  const preparedData = useCallback(
    ({ positions, contragent_id, date, number, pnl_article_id, responsible, sum }: FormValues) => {
      if (!act) return;
      if (utilityArticle && pnl_article_id !== utilityArticle.id) {
        return {
          act: {
            contragent_id,
            date: dayjs(date).format("YYYY-MM-DD"),
            number,
            id: act.id,
          },
          positions: { act_service_id: act.id, pnl_article_id, sum: sum ?? 0 },
        };
      }

      if (positions)
        return {
          act: {
            contragent_id,
            date: dayjs(date).format("YYYY-MM-DD"),
            number,
            id: act.id,
          },
          positions,
        };
    },
    [act, utilityArticle]
  );

  const onUpdate = useCallback(
    async (values: FormValues) => {
      if (!act) return;
      const data = preparedData(values);
      if (!data) return;
      fetchUpdateActWithPositions(data.act, data.positions).then(() => {
        showNotificationModal({ title: "Акт успешно изменён", type: "success" });
        setActIsLoading(false);
        return;
      });
    },
    [act, fetchUpdateActWithPositions, preparedData, setActIsLoading]
  );

  const onSave = useMemo(() => {
    if (isCreate) return onCreate;
    return onUpdate;
  }, [isCreate, onCreate, onUpdate]);

  const onApprove = useCallback(
    (values: FormValues) => {
      if (!act) return;
      const data = preparedData(values);
      if (!data) return;
      approveStatusConfirm(
        "act_service",
        () => navigate(".."),
        () => fetchUpdateActWithPositions(data.act, data.positions)
      );
    },
    [act, approveStatusConfirm, fetchUpdateActWithPositions, navigate, preparedData]
  );

  const methods = useForm<FormValues>({
    defaultValues: { sum: null },
    resolver: yupResolver(actSchema),
  });
  const { handleSubmit, setValue, resetField, reset } = methods;

  useEffect(() => {
    if (!profileData) {
      resetField("responsible");
      return;
    }

    setValue("responsible", `${profileData.surname} ${profileData.name}`);
  }, [profileData, resetField, setValue]);

  if (actIsLoading) {
    return (
      <div className={cls["wrapper"]}>
        <ActServiceHeader isCreate={isCreate} onSave={() => {}} onApprove={() => {}} />
        <BigSkeleton />
      </div>
    );
  }

  return (
    <div className={cls["wrapper"]}>
      <ActServiceHeader
        isCreate={isCreate}
        onSave={handleSubmit(onSave, err => console.log(err))}
        onApprove={handleSubmit(onApprove, err => console.log(err))}
      />
      <FormProvider {...methods}>
        <Form />
      </FormProvider>
    </div>
  );
};

export const ActServices = observer(ActServicesComponent);
