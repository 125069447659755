export enum ShowAlertPopupType {
  SUCCESS = "success",
  ERROR = "error",
  ERROR_INFO = "error-info",
  PENDING = "pending",
}

export enum KitchenStaff {
  "Starshiy-pizzayolo" = "Старший пиццайоло",
  "Pizzayolo" = "Пиццайоло",
  "Pizzayolo-intern" = "Пиццайоло-стажер",
}
