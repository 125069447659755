import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";

//Функция onInput, которая ограничивает ввод до 2 знаков после точки
const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;

  if (value.includes(".")) {
    const [integer, decimal] = value.split(".");
    if (decimal.length > 1) {
      e.target.value = `${integer}.${decimal.substring(0, 2)}`;
    }
  }
};
interface SumFieldProps {
  allDisabled: boolean;
}

const SumFieldComponent: FC<SumFieldProps> = ({ allDisabled }) => {
  const { control } = useFormContext<{ sum: number }>();

  return (
    <Input
      label="Стоимость, руб"
      control={control}
      variant={EInputStyleVariant.basicInput}
      placeholder="Введи стоимость услуги"
      type="number"
      name="sum"
      disabled={allDisabled}
      inputProps={{ onInput }}
    />
  );
};

export const SumField = SumFieldComponent;
