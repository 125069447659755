import { PnlReportRowDataTest } from "pages/pnl-beta/types";
import { api } from "./api-new";
import { onError } from "./schedulePlanning";

export const getPnlData = async (period: string) => {
  try {
    const response = await api.post<PnlReportRowDataTest[]>(`reports/pnl/get`, {
      period,
    });

    return response.data;
  } catch (error) {
    return onError(error);
  }
};
