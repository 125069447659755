import { createContext, useCallback, useEffect, useMemo } from "react";
import cls from "./styles.module.scss";
import { BigSkeleton } from "ui";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "store";
import { FormProvider, useForm } from "react-hook-form";
import { TransactionHeader } from "./components/header";
import { Form } from "./forms/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSchema } from "./forms/validation";
import dayjs from "dayjs";
import { IMoneyTransaction, MoneyTransactionDynamicFormFields } from "types/money-transactions";
import { updateDisabledProperty } from "./functions";
import { toJS } from "mobx";

export const TransactionContext = createContext<{ isCash: boolean }>({ isCash: false });

export const BankStatementEditPage = observer(() => {
  const {
    RootStore: {
      EditMoneyTransactionStore: {
        types,
        employees,
        businessHolders,
        transaction,
        transactionIsLoading,
        fetchGetTransaction,
        setTransaction,
        payTypes,
        operationTypes,
        cashFlowArticles,
        pnlArticles,
        contragents,
        commitments,
        taxes,
        fetchGetTaxes,
        fetchGetEmployees,
        fetchGetBusinessHolders,
        fetchGetContragents,
        fetchGetPnLArticles,
        fetchGetCommitments,
        fetchGetTransactionTypes,
        fetchGetTransactionPayTypes,
        fetchGetTransactionCashFlowArticles,
        fetchGetTransactionOperationTypes,
        formFields,
        setFormFields,
        fetchCreateTransaction,
        updateTransactionConfirm,
        approveTransactionConfirm,
      },
    },
  } = useStore();

  const defaultValues: Partial<IMoneyTransaction> = useMemo(() => {
    if (!transaction) return { type_id: 1 };

    const contragent_id = transaction.contragent_title !== null ? transaction.contragent_id : null;

    return { ...transaction, contragent_id };
  }, [transaction]);

  const location = useLocation();
  const navigate = useNavigate();

  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(createSchema(formFields, !!transaction)),
    reValidateMode: "onChange",
  });

  const { reset, setValue, handleSubmit, watch } = methods;
  const selectedOperationId = watch("operation_type_id");

  useEffect(() => {
    if (!payTypes.length) fetchGetTransactionPayTypes();
    if (!operationTypes.length) fetchGetTransactionOperationTypes();
    if (!cashFlowArticles.length) fetchGetTransactionCashFlowArticles();
    if (!contragents.length) fetchGetContragents();
    if (!types.length) fetchGetTransactionTypes();
    if (!employees.length) fetchGetEmployees();
    if (!businessHolders.length) fetchGetBusinessHolders();
    if (!pnlArticles.length) fetchGetPnLArticles();
    if (!commitments.length) fetchGetCommitments();
    if (!taxes.length) fetchGetTaxes();
  }, []);

  useEffect(() => {
    const id = Number(location.hash.slice(1));
    if (isNaN(id)) return;
    if (location.hash.length === 0) return;

    fetchGetTransaction(id);

    return () => setTransaction(null);
  }, [location.hash]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    const operationType = operationTypes.find(op => op.id === selectedOperationId);
    if (!transaction) {
      setFormFields(updateDisabledProperty(toJS(operationType)?.fields ?? {}) ?? null);
    } else {
      setFormFields(operationType?.fields ?? null);
    }
    if (operationType?.id === defaultValues.operation_type_id) {
      setValue(
        MoneyTransactionDynamicFormFields.CashFlowArticleId,
        defaultValues.cash_flow_article_id
      );
      setValue(
        MoneyTransactionDynamicFormFields.PnLArticleId,
        (defaultValues as any).pnl_article_id ?? null
      );
      return;
    }

    setValue(
      MoneyTransactionDynamicFormFields.CashFlowArticleId,
      operationType?.fields.cash_flow_article_id?.default
    );
    setValue(
      MoneyTransactionDynamicFormFields.PnLArticleId,
      operationType?.fields.pnl_article_id?.default
    );
  }, [operationTypes, selectedOperationId]);

  useEffect(() => {
    if (!formFields) return;
    const valuesFromDefFields: { [key: string]: any } = {};
    Object.entries(formFields).forEach(
      ([key, props]) => (valuesFromDefFields[key] = props.default)
    );
    reset(formFields, { keepValues: true, keepDefaultValues: true });
  }, [formFields]);

  const onSubmit = useCallback(
    (values: any) => {
      const contragentType =
        formFields?.contragent_type?.default === 1
          ? "user"
          : formFields?.contragent_type?.default === 2
          ? "business_holder"
          : "contragent";

      if (!transaction) {
        fetchCreateTransaction({
          ...values,
          date_delivery: values.date_delivery
            ? dayjs(values.date_delivery).format("YYYY-MM-DD")
            : null,
          period: values.period ? dayjs(values.period).format("YYYY-MM-DD") : null,
          items: values.items?.length > 0 ? values.items : null,
          amount: Number(values.amount),
          contragent_type: contragentType,
          contragent_inn: values.contragent_inn === "" ? null : values.contragent_inn,
          deal_title: values.deal_title === "" ? null : values.deal_title,
        }).then(transaction => navigate(`../edit#${transaction?.id}`));
        return;
      }
      console.log(values.period);

      updateTransactionConfirm({
        ...values,
        date_delivery: values.date_delivery
          ? dayjs(values.date_delivery).format("YYYY-MM-DD")
          : null,
        period: values.period ? dayjs(values.period).format("YYYY-MM-DD") : null,
        items: values.items?.length > 0 ? values.items : null,
        amount: Number(values.amount),
        contragent_type: contragentType,
        contragent_inn: values.contragent_inn === "" ? null : values.contragent_inn,
        deal_title: values.deal_title === "" ? null : values.deal_title,
      });
    },
    [
      transaction,
      updateTransactionConfirm,
      formFields?.contragent_type?.default,
      fetchCreateTransaction,
      navigate,
    ]
  );

  const onApprove = useCallback(
    (values: any) => {
      approveTransactionConfirm(
        {
          ...values,
          date_delivery: values.date_delivery
            ? dayjs(values.date_delivery).format("YYYY-MM-DD")
            : null,
          period: values.period ? dayjs(values.period).format("YYYY-MM-DD") : null,
          items: values.items.length > 0 ? values.items : null,
          amount: Number(values.amount),
          contragent_inn: values.contragent_inn === "" ? null : values.contragent_inn,
          deal_title: values.deal_title === "" ? null : values.deal_title,
          contragent_type:
            formFields?.contragent_type?.default === 1
              ? "user"
              : formFields?.contragent_type?.default === 2
              ? "business_holder"
              : "contragent",
        },
        () => navigate(-1)
      );
    },
    [approveTransactionConfirm, formFields?.contragent_type?.default, navigate]
  );

  const isCash = useMemo(() => transaction?.type_title === "Касса", [transaction]);

  if (transactionIsLoading) return <BigSkeleton padding="16px" />;

  return (
    <TransactionContext.Provider value={{ isCash }}>
      <div className={cls["wrapper"]}>
        <TransactionHeader
          onSave={handleSubmit(onSubmit, errors => console.log(errors))}
          onApprove={handleSubmit(onApprove)}
        />
        <FormProvider {...methods}>
          <Form />
        </FormProvider>
      </div>
    </TransactionContext.Provider>
  );
});
