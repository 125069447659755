import {type FC, useEffect, useState} from 'react';
import type {ChangeItem, OddsTableItem, TableItemCreate} from "../../../../types/odds";
import type {OddsDay} from "../../types";
import {TableCell} from "../table-cell";
import styles from './styles.module.scss';
import classNames from "classnames";

interface Props {
    data: OddsTableItem;
    isCategory?: boolean;
    isSubCategory?: boolean;
    days: OddsDay[];
    updateTableItems: (data: OddsTableItem, day: OddsDay, editedItem: TableItemCreate) => void;
    updateServerState: (items: ChangeItem[]) => void;
}

const TableRow: FC<Props> = ({ data, isCategory, isSubCategory, days, updateTableItems, updateServerState }) => {

    const hasItems = data.items?.length > 0
    const [changedItems, setChangedItems] = useState<ChangeItem[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);

    const rowStyle = {
        backgroundColor: isExpanded ? '#F3EEFF' : (isCategory ? '#f7f1ea': 'white'),
        fontWeight: isSubCategory ? (hasItems ? 'bold' : 'normal') : "bold",
        verticalAlign: 'middle',
        fontSize: isSubCategory ? "14px" : "16px"
    }

    const toggleExpand = () => {
        setIsExpanded(prev => !prev);
    };

    const updateChangedItems = (items: ChangeItem[], el: TableItemCreate) => {
        const index = items.findIndex((item) => item.code === el.code && item.date === el.date);

        if (index === -1) {
            items.push(el);
        } else {
            items[index].amount = el.amount;
        }

        return items;
    }

    const onSubmit = (data: OddsTableItem, day: OddsDay, inputValue: string | number) => {
        const editedItem: TableItemCreate = {
            date: day.date,
            amount: Number(inputValue).toFixed(2),
            code: data.code,
        };

        setChangedItems((prev) => updateChangedItems(prev, editedItem));

        updateServerState(changedItems);
        updateTableItems(data, day, editedItem);
    };

    return (
        <>
            <tr
                className={isCategory ? styles['category'] : styles['subcategory']}
                style={{ cursor: hasItems ? 'pointer' : 'default' }}
            >
                <td
                    className={styles.title}
                    style={rowStyle}
                    onClick={toggleExpand}
                >
                    <span
                        className={classNames(styles.expandArrow, isExpanded ? styles.expanded : '')}
                        style={{ marginRight: '8px' }}
                    >
                      {hasItems && (isExpanded ? '−' : '>')}
                    </span>
                    {data.title}
                </td>

                {days.map((day, index) => (
                    <TableCell data={data} day={day} styles={rowStyle} onSubmit={onSubmit} code={data.code} />
                ))}
            </tr>
            {isExpanded &&
                Array.isArray(data.items) &&
                data.items.map((detail, detailIndex) => (
                    <TableRow
                        days={days}
                        key={detailIndex}
                        data={detail}
                        isCategory={!detail.items.length}
                        updateTableItems={updateTableItems}
                        isSubCategory={true}
                        updateServerState={updateServerState}
                    />
                ))}
        </>
    );
};

export default TableRow;
