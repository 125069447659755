import { FC, useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EInputStyleVariant, Input } from "ui-new/input";
import { TransactionContext } from "../..";
import { observer } from "mobx-react-lite";
import { useStore } from "store";

interface SourceFieldProps {
  allDisabled: boolean;
}

const SourceFieldComponent: FC<SourceFieldProps> = () => {
  const {
    RootStore: {
      EditMoneyTransactionStore: { transaction },
    },
  } = useStore();

  const { control } = useFormContext<{ spot_bank_account_number: string }>();
  const { isCash } = useContext(TransactionContext);

  const postfix = !transaction || isCash ? "Касса" : "Расчетный счет";

  return (
    <Controller
      name="spot_bank_account_number"
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          label="Источник"
          variant={EInputStyleVariant.basicInput}
          type="text"
          inputProps={{
            ...field,
            disabled: true,
            value: `${postfix} ${field.value || ""}`,
          }}
        />
      )}
    />
  );
};

export const SourceField = observer(SourceFieldComponent);
