import { observer } from "mobx-react-lite";
import { FC, ReactNode, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import cls from "./table-styles.module.scss";
import { useStore } from "store";
import { addSpaces } from "assets/utils";

interface CommonInfoTableProps {
  days: Dayjs[];
}

const CommonInfoTableComponent: FC<CommonInfoTableProps> = ({ days }) => {
  const {
    RootStore: {
      SalaryFundStore: { bepInfo },
    },
  } = useStore();

  const [revenueData, baseData, baseHours, managerHours] = useMemo(() => {
    const revenueData: ReactNode[] = [];
    const baseData: ReactNode[] = [];
    const baseHours: ReactNode[] = [];
    const managerHours: ReactNode[] = [];

    days.forEach(d => {
      const plan = bepInfo?.plan_revenue.find(p => dayjs(p.date).isSame(d, "day"));
      const fact = bepInfo?.fact_revenue.find(p => dayjs(p.date).isSame(d, "day"));
      const hours = bepInfo?.hours.find(p => dayjs(p.date).isSame(d, "day"));

      revenueData.push(
        <>
          <td className={cls["td"]}>{addSpaces((plan?.amount ?? 0).toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces((fact?.amount ?? 0).toFixed(2))}</td>
        </>
      );
      baseData.push(
        <>
          <td className={cls["td"]}>{addSpaces((plan?.base ?? 0).toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces((fact?.base ?? 0).toFixed(2))}</td>
        </>
      );
      baseHours.push(
        <>
          <td className={cls["td"]}>{hours?.sum_hours_plan ?? 0}</td>
          <td className={cls["td"]}>{hours?.sum_hours_fact ?? 0}</td>
        </>
      );
      managerHours.push(
        <>
          <td className={cls["td"]}>{hours?.manager_hours_plan ?? 0}</td>
          <td className={cls["td"]}>{hours?.manager_hours_fact ?? 0}</td>
        </>
      );
    });
    return [revenueData, baseData, baseHours, managerHours];
  }, [bepInfo?.fact_revenue, bepInfo?.hours, bepInfo?.plan_revenue, days]);

  return (
    <table className={cls["table"]}>
      <colgroup>
        <col width={365} />
        {days.map(d => (
          <>
            <col width={113} />
            <col width={113} />
          </>
        ))}
        <col width={113} />
        <col width={113} />
        <col width={113} />
      </colgroup>
      <thead className={cls["thead"]} style={{ top: "33px" }}>
        <tr className={cls["tr"]}>
          <th className={cls["th"]}></th>
          {days.map(d => (
            <>
              <th className={cls["th"]}>План</th>
              <th className={cls["th"]}>Факт</th>
            </>
          ))}
          <th className={cls["th"]}>План</th>
          <th className={cls["th"]}>Факт</th>
          <th className={cls["th"]}></th>
        </tr>
      </thead>
      <tbody className={cls["tbody"]}>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}>Выручка, ₽</td>
          {revenueData}
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
        </tr>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}>База для расчета премии(3% от выручки), ₽</td>
          {baseData}
          <td className={cls["td"]}>{addSpaces((bepInfo?.results.plan_base ?? 0).toFixed(2))}</td>
          <td className={cls["td"]}>{addSpaces((bepInfo?.results.fact_base ?? 0).toFixed(2))}</td>
          <td className={cls["td"]}></td>
        </tr>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}>Базовое количество часов для расчета, ₽</td>
          {baseHours}
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
        </tr>
        <tr className={cls["tr"]}>
          <td className={cls["td"]}>Часы управляющего</td>
          {managerHours}
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
          <td className={cls["td"]}></td>
        </tr>
      </tbody>
    </table>
  );
};

export const CommonInfoTable = observer(CommonInfoTableComponent);
