import { makeAutoObservable, toJS } from "mobx";
import { RootStore } from "../../store/rootStore";
import { getContragentsPaginated, getDeals, IDealUpdateProps, updateDeal } from "api/contracts";
import { IContragent, IDeal } from "./types";
import { showNotificationModal } from "ui-new/alert";
import { showConfirmModal } from "ui-new/alert/confirm";
import { getCashFlowArticles, getOperationTypes, IContragentMetaProps } from "api";
import {
  IMoneyTransactionCashFlowArticle,
  IMoneyTransactionOperationType,
} from "types/money-transactions";

export class ContractsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  deals: IDeal[] = [];
  contragents: IContragent[] = [];
  contragentsIsLoading = false;
  contragentsMeta: IContragentMetaProps | null = null;
  operationTypes: IMoneyTransactionOperationType[] = [];
  cashFlowArticles: IMoneyTransactionCashFlowArticle[] = [];
  selectedDeal: IDeal | null = null;

  setSelectedDeal = (deal?: IDeal) => {
    this.selectedDeal = deal ?? null;
  };

  fetchGetDeals = async () => {
    const response = await getDeals();

    if (!response) return;

    this.deals = response;
  };

  private setContragentsIsLoading = (isLoading: boolean) => {
    this.contragentsIsLoading = isLoading;
  };

  fetchGetContragents = async (searchValue?: string, page?: number) => {
    this.setContragentsIsLoading(true);
    try {
      const response = await getContragentsPaginated({
        per_page: 50,
        search: searchValue,
        page: page ?? this.contragentsMeta?.current_page,
      });

      if (!response) return;

      this.contragents =
        response.meta.current_page === 1
          ? response.data
          : [...toJS(this.contragents), ...response.data];
      this.contragentsMeta = response.meta;
    } finally {
      this.setContragentsIsLoading(false);
    }
  };

  updateDealConfirm = (args: IDealUpdateProps, onConfirm: () => void) => {
    const isEdit = !!this.selectedDeal;

    showConfirmModal({
      title: isEdit ? "Изменить договор ?" : "Добавить новый договор?",
      messages: [
        "Новые условия оплаты будут применены ко всем взаиморасчетам по данному договору.",
      ],
      confirmButtonLabel: isEdit ? "Да, изменить" : "Да, добавить",
      onConfirm: () => this.fetchUpdateDeal(args).then(resp => resp && onConfirm()),
      minWidth: "397px",
      maxWidth: "397px",
    });
  };

  fetchUpdateDeal = async (args: IDealUpdateProps) => {
    const response = await updateDeal(args);

    if (!response) return null;

    showNotificationModal({
      title: this.selectedDeal ? "Договор изменен" : "Договор добавлен",
      type: 'success',
    });
    this.fetchGetDeals();
    this.fetchGetContragents();

    return true;
  };

  fetchGetOperationTypes = async () => {
    const response = await getOperationTypes();
    if (!response) return;
    this.operationTypes = response;
  };

  fetchGetFlowArticles = async () => {
    const response = await getCashFlowArticles();
    if (!response) return;
    this.cashFlowArticles = response;
  };
}
