import React, { useMemo, useState } from "react";
import { PnlReportRowDataTest } from "../types";
import { addSpaces } from "assets/utils";

interface PnlReportRowProps {
  data: PnlReportRowDataTest;
  isCategory?: boolean; // Флаг, является ли строка категорией
  isSubCategory?: boolean;
}
//TODO: Нужно зарефакторить модуль когда будет время.
const arr = Array.from(Array(12).keys());

const PnlReportRow: React.FC<PnlReportRowProps> = ({ data, isCategory, isSubCategory }) => {
  const hasItems = data.items?.length > 0;
  //Use states
  const [isExpanded, setIsExpanded] = useState(false);

  const rowStyle = useMemo(
    () => ({
      backgroundColor: isExpanded ? "#F3EEFF" : data.is_indicator ? "#f7f1ea" : "white",
      fontWeight: isSubCategory ? (hasItems ? "bold" : "normal") : "bold",
      verticalAlign: "middle",
      fontSize: isSubCategory ? "14px" : "16px",
    }),
    [isExpanded, data.is_indicator, isSubCategory, hasItems]
  );

  const toggleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  const months = useMemo(
    () =>
      Array.isArray(data.results)
        ? data.results.map((month, index) => {
            const plan = month.plan.toFixed(2);
            const fact = month.fact.toFixed(2);

            return (
              <React.Fragment key={index}>
                <td style={rowStyle}>
                  {addSpaces(plan)}
                  {data.type === "percent" ? "%" : ""}
                </td>
                <td style={rowStyle}>
                  {addSpaces(fact)}
                  {data.type === "percent" ? "%" : ""}
                </td>
              </React.Fragment>
            );
          })
        : arr.map(() => (
            <React.Fragment>
              <td style={rowStyle}></td>
              <td style={rowStyle}></td>
            </React.Fragment>
          )), // Выводим сообщение, если данных нет
    [data.results, data.type, rowStyle]
  );

  const total = useMemo(
    () =>
      data.yearly ? (
        Object.entries(data.yearly).map(([key, value]) => (
          <td key={data.code + key} style={rowStyle}>
            {addSpaces(value.toFixed(2))}
            {data.type === "percent" ? "%" : ""}
          </td>
        ))
      ) : (
        <>
          <td style={rowStyle}></td>
          <td style={rowStyle}></td>
        </>
      ),
    [data.code, data.type, data.yearly, rowStyle]
  );

  const itemsData = useMemo(
    () =>
      isExpanded &&
      Array.isArray(data.items) &&
      data.items.map((detail, detailIndex) => (
        <PnlReportRow
          key={detailIndex}
          data={detail}
          isCategory={!detail.is_indicator}
          isSubCategory={true}
        />
      )),
    [data.items, isExpanded]
  );

  return (
    <>
      <tr
        className={isCategory ? "category" : "subcategory"}
        onClick={hasItems ? toggleExpand : undefined}
        style={{ cursor: hasItems ? "pointer" : "default" }}
      >
        <td className="title" style={rowStyle}>
          <span
            className={`expand-arrow ${isExpanded ? "expanded" : ""}`}
            style={{ marginRight: "8px" }} // Отступ справа от стрелки
          >
            {hasItems && (isExpanded ? "−" : ">")}
          </span>
          {data.title}
        </td>
        {/* Проверяем наличие results перед вызовом .map */}
        {months}
        {total}
      </tr>
      {itemsData}
    </>
  );
};

export default PnlReportRow;
