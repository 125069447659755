import { Upload } from "antd";
import { dragIcon, fileIcon, RemoveFileIcon } from "../icons";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { Modal } from "widgets/modal";
import { ModalButtons } from "widgets/modal/buttons";
import { FC, useEffect } from "react";
import cls from "./uploader-modal-styles.module.scss";

interface UploaderModalBsProps {
  onClose: () => void;

  onLoad: () => void;
}

const UploaderModalBSComponent: FC<UploaderModalBsProps> = ({ onClose, onLoad }) => {
  const {
    RootStore: {
      MoneyTransactionsStore: { fetchUploadMoneyTransactions, setFile, file },
    },
  } = useStore();

  useEffect(() => {
    return () => {
      setFile(null);
    };
  }, []);

  return (
    <Modal title={"Загрузить выписку"} maxWidth={"480px"} minWidth={"480px"} onClose={onClose}>
      <div className={cls["wrapper"]}>
        <p className={cls["subtitle"]}>Здесь вы можете загрузить выписку из банка</p>
        {file === null && (
          <Upload.Dragger
            className={`${file ? "disabled" : null}`}
            disabled={file !== null}
            name="file"
            multiple={false}
            showUploadList={false}
            accept=".txt"
            action={undefined}
            customRequest={e => {
              setFile(e.file);
            }}
          >
            <p className="ant-upload-drag-icon">{dragIcon}</p>
            <p className={cls["upload-text"]}>
              Перетащите в эту область или <span>загрузите</span> файл формата .txt
            </p>
          </Upload.Dragger>
        )}
        {file && (
          <div className={cls["attachment"]}>
            {fileIcon}
            <div>
              {file.name}
              <span>{(file.size / 1024 / 1024).toFixed(3)} Mb</span>
            </div>
            <RemoveFileIcon onClick={() => setFile(null)} />
          </div>
        )}
        <div style={{ alignSelf: "flex-end" }}>
          <ModalButtons
            onPrimaryButtonClick={() => {
              fetchUploadMoneyTransactions();
              onClose();
              onLoad();
            }}
            primaryButtonLabel={"Загрузить"}
            secondaryButtonLabel={"Отменить"}
            onSecondaryButtonClick={onClose}
            primaryButtonDisabled={file === null}
          />
        </div>
      </div>
    </Modal>
  );
};

export const UploaderModalBS = observer(UploaderModalBSComponent);
