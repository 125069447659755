import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.locale('ru');

export {
    dayjs
}